// MarkAttendanceModal.js

import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  ListItemIcon,
  Tooltip,
  ListItemButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import moment from "moment";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import UserIconImage from "../image/UserIconImage.png";
import { BASE_URL, DOMAIN } from "../../global";
import { toast } from "react-toastify";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const multiValueModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  "@media (max-width: 600px)": {
    width: "80%", // Adjust the width for screens up to 768px wide
  },
  "@media (max-width: 480px)": {
    width: "90%", // Adjust the width for screens up to 480px wide
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  maxHeight: "550px",
  overflow: "auto",
  padding: "30px",
};

const MarkAttendanceModal = ({ isMobile }) => {
  const [open, setOpen] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isCheckIn, setIsCheckIn] = useState(true);
  const [attendanceId, setAttendanceId] = useState(null);
  const [allAddressIds, setAllAddressIds] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const userId = localStorage.getItem("id");
  const videoRef = useRef(null);
  const [downloadPdfloading, setDownlaodPdfLoading] =React.useState(false);
  let cameraStream;

  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleStartCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraActive(true);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      cameraStream = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCameraStream = () => {
    if (cameraStream) {
      const tracks = cameraStream.getTracks();
      tracks.forEach((track) => {
        track.stop(); // Stop each track in the stream
      });
    }
  };

  const handleCapturePhoto = async () => {
    try {
      const canvas = document.createElement("canvas");
      const video = videoRef.current;
      if (video) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageUrl = canvas.toDataURL("image/jpeg");
        handleUploadPhoto(imageUrl);
        setImageData(imageUrl);
        setCameraActive(false);

        // Stop the camera stream after capturing the photo
        stopCameraStream();
      }
    } catch (error) {
      console.error("Error capturing photo:", error);
    }
  };

  const handleUploadPhoto = async (imageUrl) => {
    setDownlaodPdfLoading(true);
    try {
      const uploadedImageUrl = await handleFileUpload12(imageUrl);
      setUploadedImageUrl(uploadedImageUrl);
      setDownlaodPdfLoading(false);
      setCameraActive(false);
    } catch (error) {
      console.error("Error uploading photo:", error);
      setDownlaodPdfLoading(false);
    }
  };

  const handleFileUpload12 = async (imageData) => {
    // Convert Base64 to a Blob
    const byteCharacters = atob(imageData.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const data = new FormData();
    data.append("fileToUpload", blob, "images.jpg");

    try {
      const res = await axiosWithToken.post(
        BASE_URL + "fileUpload/fileUpload.php",
        data
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else {
        toast.error("Something Went Wrong! for Image Upload");
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      let trace = new Error().stack;
      toast.error(<h6 onClick={() => openErrorModal(err.message, trace,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
      throw err;
    }
  };

  const handleOpen = () => {
    getLocation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCameraActive(false);
    setImageData(null);
    setUploadedImageUrl(null);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          console.log(err.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = () => {
    setDownlaodPdfLoading(true);
    let checkInPayload = {
      userId: userId,
      addressId: selectedLocation,
      checkinPhotoPath: uploadedImageUrl,
      checkinLat: latitude,
      checkinLang: longitude,
    };

    let checkOutPayload = {
      userId: userId,
      addressId: selectedLocation,
      checkoutPhoto: uploadedImageUrl,
      checkoutLat: latitude,
      checkoutLang: longitude,
      checkoutDatetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };

    let url = BASE_URL;
    if (attendanceId && !isCheckIn) {
      axiosWithToken
        .patch(url + `attendance/${attendanceId}`, checkOutPayload)
        .then((res) => {
          handleClose();
          setDownlaodPdfLoading(false);
          setTimeout(refresh, 500);
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "handleSubmit" ,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
          setDownlaodPdfLoading(false);
        });
    } else {
      axiosWithToken
        .post(url + `attendance`, checkInPayload)
        .then((res) => {
          handleClose();
          setDownlaodPdfLoading(false);
          setTimeout(refresh, 500);
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "handleSubmit" ,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
          setDownlaodPdfLoading(false);
        });
    }
  };

  const fetchData = () => {
    let sqlQuery = {
      query: `SELECT * FROM attendance WHERE userId = ${userId} AND DATE(checkinDatetime) = CURDATE() ORDER BY id DESC`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then((res) => {
        setAttendanceId(res?.data?.[0]?.id);
        if (res?.data?.length > 0) {
          if (res?.data?.[0]?.checkoutPhoto) {
            setIsCheckIn(true);
          } else {
            setSelectedLocation(res?.data?.[0]?.addressId);
            setIsCheckIn(false);
          }
        }
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchData" ,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchCompanyIds = () => {
    let sqlQuery = {
      query: `
        SELECT c.Address FROM userClientAssign uca JOIN clients c ON uca.companyId = c.id WHERE uca.userId = ${userId}`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then((response) => {
        let allAddresses =
          response?.data?.map((address) => address?.Address) || [];

        // Split combined values and flatten the array
        let flattenedAddresses = allAddresses.flatMap((address) =>
          address.split(",")
        );

        setAllAddressIds(flattenedAddresses.join(","));
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchCompanyIds" ,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchAllAddress = () => {
    let sqlQuery = {
      query: `
        SELECT * FROM addresses WHERE id IN (${allAddressIds || 0});`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then((response) => {
        setAddressList(response?.data);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchAllAddress" ,"MarkAttendanceModal")}><u>Error: {err.message}</u></h6>);
      });
  };

  const validateData = () => {
    let isValid = true;

    if (!longitude) {
      toast.error("Please give permission to take your location");
      isValid = false;
    }
    

    return isValid;
  };

  useEffect(() => {
    fetchAllAddress();
  }, [allAddressIds]);

  useEffect(() => {
    fetchData();
    fetchCompanyIds();
  }, []);

  return (
    <div>
      {!isMobile ? (
        <Button onClick={handleOpen}>
          <LibraryAddCheckIcon style={{ marginRight: "10px" }} />
          Mark Attendance
        </Button>
      ) : (
        <ListItemButton>
          <ListItemIcon>
            <Tooltip title="Mark Attendance" arrow placement="right">
              <LibraryAddCheckIcon />
            </Tooltip>
          </ListItemIcon>
          <Typography
            variant="p"
            component="div"
            align="left"
            onClick={handleOpen}
          >
            {"Mark Attendance"}
          </Typography>
        </ListItemButton>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={multiValueModalStyle}>
          <Box style={{ padding: "20px" }}>
            <Autocomplete
              options={addressList}
              value={
                addressList?.find((ele) => ele.id == selectedLocation) || null
              }
              getOptionLabel={(option) => option?.address}
              disabled={!isCheckIn}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Location......."
                  placeholder="Location..."
                  // sx={{ minWidth: '150px' }}
                />
              )}
              onChange={(event, newValue) => setSelectedLocation(newValue?.id)}
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" disabled={!isCheckIn} size="small">
              CheckIn
            </Button>
            <Button variant="contained" disabled={isCheckIn} size="small">
              CheckOut
            </Button>
          </Box>
          <Typography
            variant="h6"
            color="initial"
            style={{ textAlign: "center", margin: "10px 0px" }}
          >{`Latitude:${latitude},  Longitude:${longitude} `}</Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column", // Change to column on small screens
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              padding: "25px",
            }}
          >
            {cameraActive && (
              <video
                ref={videoRef}
                autoPlay
                style={{ height: "200px", width: "100%" }}
              ></video>
            )}

            {!cameraActive && (
              <img
                src={uploadedImageUrl || imageData || UserIconImage}
                style={{ height: "200px", width: "80%", marginBottom: "10px" }}
                alt="User Icon"
              />
            )}

            {!cameraActive && (
              <Button
                onClick={handleStartCamera}
                variant="contained"
                size="small"
                style={{ width: "100%" }}
              >
                Start Camera
              </Button>
            )}

            {cameraActive && (
              <div style={{ width: "100%" }}>
                <Button
                  onClick={handleCapturePhoto}
                  variant="contained"
                  size="small"
                  style={{ width: "100%" }}
                >
                  Capture Photo
                </Button>
              </div>
            )}
          </Box>

          <Box
            style={{ display: "flex", gap: "15px", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleClose();
                refresh();
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              disabled={!uploadedImageUrl}
              size="small"
              // onClick={handleSubmit}
              onClick={() => {
                if (!validateData()) return;
                handleSubmit()             
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      {downloadPdfloading && <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Image Upload"} />}
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}

    </div>
  );
};
export default MarkAttendanceModal;
