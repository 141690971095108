import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  useMediaQuery,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tables from "../../../utils/components/tables";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExcelUpload from "../../../utils/components/excelUpload";
import { ducMasterListGuide } from "./guide";
import { allSettings } from "../../../utils/components/allSettings";
import ExcelDownload from "../../../utils/components/excelDownload";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyName",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "DUCID",
    label: "DUCID",
    align: "left",
    minWidth: 40,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    align: "left",
    minWidth: 40,
  },

  {
    id: "make",
    label: "Make",
    align: "left",
    minWidth: 40,
  },
  {
    id: "model",
    label: "Model",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ranges",
    label: "Ranges",
    align: "left",
    minWidth: 40,
  },
  {
    id: "lc",
    label: "LC",
    align: "left",
    minWidth: 40,
  },
  {
    id: "locationOfInstrument",
    label: "Location Of Instrument",
    align: "left",
    minWidth: 40,
  },
  {
    id: "department",
    label: "Department",
    align: "left",
    minWidth: 40,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calFrequency",
    label: "Cal Frequency",
    align: "left",
    minWidth: 40,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let notNeededColumn = ["id","instrumentId","clientId","lastModified"];

const DucMasterList = () => {
  const ducMasterListGuides = ducMasterListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "DUCID");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "	Client Name": "companyName",
        "Instrument Name": "instrumentName",
        "Location Of Instrument": "locationOfInstrument",
        Department: "department",
        DUCID: "DUCID",
        "Serial Number": "serialNumber",
        "Model": "model",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT clients.companyName, instruments.instrumentName, ducMaster.*, REPLACE(REPLACE(REPLACE(ducMaster.ranges, '#', ''), '||', ','), '|', ' to ') AS ranges, REPLACE(REPLACE(REPLACE(ducMaster.lc, '#', ''), '||', ','), '|', ' to ') AS lc, REPLACE(REPLACE(REPLACE(ducMaster.accuracy, '#', ''), '||', ','), '|', ' to ') AS accuracy FROM ducMaster JOIN clients ON ducMaster.clientId = clients.id JOIN instruments ON ducMaster.instrumentId = instruments.id ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM ducMaster JOIN clients ON ducMaster.clientId = clients.id JOIN instruments ON ducMaster.instrumentId = instruments.id  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
      });
  }

  function fetchFinalData() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchFinalData" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    setSearched(true);
    fetchFinalData();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    setSearchBy("DUCID");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    const confirmed = window.confirm("Really want to delete DUC?");
    if (!confirmed) {
      return;
    }
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `ducMaster/${id}`)
      .then((res) => {
        toast("ducMaster is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "handleDelete" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
      });
  };

  const copyDUC = async (id) => {
    const confirmed = window.confirm(
      "Do want to copy and create new DUC using selected DUC?"
    );
    if (!confirmed) {
      return;
    }

    let ducData = await axiosWithToken
      .get(BASE_URL + `ducMaster/${id}`)
      .then((res) => res.data[0] || []);
    delete ducData.id;
    delete ducData.lastModified;

    axiosWithToken.post(BASE_URL + "ducMaster", ducData).then((res) => {
      setTimeout(refresh, 500);
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (searched==false) {
    fetchFinalData();
    }
  }, [rowsPerPage,page, searched]);

  const ExcelModal = () => {
    const [open, setOpen] = React.useState(false);
    const [excelArray, setExcelArray] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [instruments, setInstruments] = React.useState([]);
    const [additionalColumns, setAdditionalColumns] = React.useState([]);
    const handleOpen = () => setOpen(true);

    const fetchClientsData = () => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + "clients?_fields=id,companyName&_where=(status,eq,1)")
        .then((res) => {
          setClients(res.data);
        })
        .catch((err) => {
          console.log("clients data error: ", err);
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchClientsData" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
        });
    };

    const fetchInstrumentsData = () => {
      let url = BASE_URL;
      axiosWithToken
        .get(url + "instruments?_fields=id,instrumentName&_where=(status,eq,1)")
        .then((res) => {
          setInstruments(res.data);
        })
        .catch((err) => {
          console.log("instruments data error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchInstrumentsData" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
      });
    };

    const fetchSetting = async () => {
      try {
        const AllSettings = await allSettings();
        let additionalColumns = AllSettings.find(
          (ele) => ele.keyName === "srfInstrumentsAdditionalColumns"
        );
        if (additionalColumns) {
          let splitColums = additionalColumns?.value?.split(",");
          setAdditionalColumns(splitColums);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    excelArray.forEach((item) => {
      const extraColumns = {};

      additionalColumns.forEach((key) => {
        const trimmedKey = key;
        if (item.hasOwnProperty(trimmedKey)) {
          extraColumns[trimmedKey] = item[trimmedKey];
        } else {
          extraColumns[trimmedKey] = "";
        }
      });

      item.extraColumns = JSON.stringify(extraColumns);
    });

    // Arrays to hold rejected objects and remaining objects
    let rejectedData = [];
    let remainingData = [];

    // Check for clientId and instrumentId
    for (const excelObj of excelArray) {
      const foundClient = clients.find(
        (client) => client.id === excelObj.clientId
      );
      const foundInstrument = instruments.find(
        (instrument) => instrument.id === excelObj.instrumentId
      );

      if (!foundClient) {
        const rejectedObj = {
          ...excelObj,
          rejectionStatus: "Client is not found in Client database",
        };
        rejectedData.push(rejectedObj);
      } else if (!foundInstrument) {
        const rejectedObj = {
          ...excelObj,
          rejectionStatus: "Instrument is not found in Instrument database",
        };
        rejectedData.push(rejectedObj);
      } else {
        remainingData.push(excelObj);
      }
    }

    var refresh = () => {
      window.location.reload(false);
    };

    const handleSubmit = () => {
      axiosWithToken
        .post(BASE_URL + "ducMaster/bulk", remainingData)
        .then((res) => {
          setTimeout(refresh, 500);
          toast.success("Excel data uploaded Successfully!");
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "handleSubmit" ,"Duc MasterList")}><u>Error: {err.message}</u></h6>);
        });
    };
    const handleClose = () => {
      setExcelArray([]);
      rejectedData = [];
      remainingData = [];
      setOpen(false);
    };

    useEffect(() => {
      fetchSetting();
      fetchClientsData();
      fetchInstrumentsData();
    }, []);

    return (
      <div>
        <Button
          id="ducMaster_list_uploadexcel"
          variant="contained"
          // size="small"
          onClick={handleOpen}
        >
          Upload Excel File
        </Button>
        {open && 
        <Modal
          open={open}
          onClose={handleClose}
          // BackdropComponent={(props) => (
          //   <div
          //     {...props}
          //     style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1300 }}
          //   />
          // )}
          style={{maxHeight:"90vh", overflow: "scroll"}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{ textAlign: "center" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Upload Excel for DUC Master
            </Typography>

            <Typography
              sx={{ textAlign: "right", fontSize: "15px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {`Rejected Data:${rejectedData.length}, Accepted Data:${remainingData.length}`}
            </Typography>

            <Box style={{ margin: "auto" }}>
              <ExcelUpload
                setExcelArray={setExcelArray}
                buttonName={"Select Excel File"}
                isDUCMaster={true}
              />
            </Box>

            <Typography
              sx={{ textAlign: "center", margin: "15px 0px" }}
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              Rejected Excel Data
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Rejected Data Table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Instrument ID</TableCell>
                    <TableCell>DUCID</TableCell>
                    <TableCell>Rejection Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.clientId}</TableCell>
                      <TableCell>{row.instrumentId}</TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.rejectionStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              style={{
                margin: "20px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                component="span"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                component="span"
                disabled={!remainingData.length > 0}
                onClick={handleSubmit}
              >
                continue
              </Button>
            </Box>
          </Box>
        </Modal>
  }
      </div>
    );
  };

  const actions = [
    {
      id: "edit",
      tooltip: "Edit DUC ",
      link: true,
      linkTo: (id) => `/master/editDucMaster/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "copy",
      tooltip: "Copy DUC ",
      icon: <ContentCopyIcon />,
      handler: (id) => copyDUC(id),
    },
    {
      id: "delete",
      tooltip: "Delete DUC ",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (id) => handleDelete(id),
    },
  ];

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="ducMaster_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          options={
            _userType != 3
              ? [
                  { key: "companyName", label: "	Client Name" },
                  { key: "instrumentName", label: "Instrument Name" },
                  { key: "DUCID", label: "DUCID" },
                  { key: "serialNumber", label: "Serial Number" },
                  { key: "model", label: "Model" },
                  {
                    key: "locationOfInstrument",
                    label: "Location Of Instrument",
                  },
                  { key: "department", label: "Department" },
                ]
              : [{ key: "companyName", label: "	Client Name" }]
          }
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="ducMaster_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="ducMaster_list_searchbtn"
          variant="contained"
          size="small"
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
      {!isMobile && (
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <ExcelModal />
        </Grid>
      )}
      {!isMobile && (
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <ExcelDownload finalData={data} notNeededColumn={notNeededColumn} />
        </Grid>
      )}
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          {showComponent("add") && (
            <Button
              id="ducMaster_list_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/master/editDucMaster"
              // onClick={() => {
              //   setTitle("Master/ add new user");
              // }}
            >
              <b>create duc Master</b>
            </Button>
          )}
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                ducMasterListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <Tables
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleDelete={handleDelete}
        columns={columns}
        finalData={data}
        actions={actions}
        totalRows={totalRows}
      />
        {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Box>
  );
};

export default DucMasterList;
