import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export default function UpdatePickup(props) {
  const [nameOfTheCompany, setNameOfTheCompany] = React.useState("");
  const [contactPersonName, setContactPersonName] = React.useState("");
  const [date, setDate] = React.useState("");
  const [phone, setPhone] = React.useState([]);
  const [location, setLocation] = React.useState([]);
  const [email, setEmail] = React.useState([]);
  const [quantityOfInstruments, setQuantityOfInstruments] = React.useState([]);
  const [purposeOfPickUp, setPurposeOfPickUp] = React.useState([]);
  const [distance, setDistance] = React.useState([]);
  const params = useParams();

  const getPickupList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `pickup/${params.id}`)
      .then((res) => {
        setNameOfTheCompany(res.data[0].nameOfTheCompany);
        setContactPersonName(res.data[0].contactPersonName);
        setDate(res.data[0].date);
        setPhone(res.data[0].phone);
        setLocation(res.data[0].location);
        setEmail(res.data[0].email);
        setQuantityOfInstruments(res.data[0].quantityOfInstruments);
        setPurposeOfPickUp(res.data[0].purposeOfPickUp);
        setDistance(res.data[0].distance);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getPickupList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      nameOfTheCompany: nameOfTheCompany,
      contactPersonName: contactPersonName,
      date: date ? moment(date).format("YYYY-MM-DD") : moment(new Date()),
      phone: phone,
      location: location,
      email: email,
      quantityOfInstruments: quantityOfInstruments,
      purposeOfPickUp: purposeOfPickUp,
      distance: distance,
    };

    let url = BASE_URL;

    axiosWithToken
      .patch(url + `pickup/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("Updated Pickup!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Pickup
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Name Of The Company"
            size="small"
            fullWidth
            value={nameOfTheCompany}
            variant="outlined"
            onChange={(e) => {
              setNameOfTheCompany(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Person Name"
            size="small"
            value={contactPersonName}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setContactPersonName(e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={date ? date : new Date()}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Phone"
            value={phone}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Location"
            size="small"
            value={location}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email"
            size="small"
            value={email}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quantity Of Instruments"
            size="small"
            fullWidth
            value={quantityOfInstruments}
            variant="outlined"
            onChange={(e) => {
              setQuantityOfInstruments(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Purpose Of Pickup"
            size="small"
            value={purposeOfPickUp}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPurposeOfPickUp(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Distance"
            value={distance}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDistance(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
