import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No" },
  { id: "standards", label: "Standards", minWidth: 40 },
  { id: "procedure", label: "Procedures", minWidth: 40 },
  { id: "checkPoint", label: "Check Point", minWidth: 40 },
  { id: "acceptanceCriteria", label: "Acceptance Criteria", minWidth: 40 },
  { id: "frequency", label: "Frequency", minWidth: 40 },
];

function createData(
  id,
  standards,
  procedure,
  checkPoint,
  acceptanceCriteria,
  frequency
) {
  return {
    id,
    standards,
    procedure,
    checkPoint,
    acceptanceCriteria,
    frequency,
  };
}

export default function IntermediateProcedureList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [intermediateProcedure, setIntermediateProcedure] = React.useState([]);
  const [listData, setListData] = React.useState([]);
  const [standardData, setStandardData] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getIntermediateProcedureList = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "intermediateProcedure?_where=(status,eq,1)")
      .then((res) => {
        setIntermediateProcedure(res.data);
        initializeDataTable();
        res.data.map((item) => {
          axiosWithToken
            .get(url + `standards/bulk?_ids=${item.standardIds}`)
            .then((res) => {
              setStandardData(res.data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  useEffect(() => {
    getIntermediateProcedureList();
  }, []);

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `intermediateProcedure/${id}`, jsonData)
      .then((res) => {
        toast("Intermediate Procedure was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const initiatIntermediateProcedureList = () => {
    var rows = [];
    var stId = [];
    for (let i = 0; i < intermediateProcedure.length; i++) {
      stId.push(intermediateProcedure[i].standardIds.split(","));
    }

    var stData = [];

    for (let i = 0; i < standardData.length; i++) {
      for (let j = 0; j < stId.length; j++) {
        if (parseInt(stId[i][j]) == standardData[i].id) {
          stData.push(standardData[i].standardName);
        }
      }
    }
    console.log(stData);

    for (let i = 0; i < intermediateProcedure.length; i++) {
      rows.push(
        createData(
          intermediateProcedure[i].id,
          intermediateProcedure[i].standardIds,
          intermediateProcedure[i].procedures,
          intermediateProcedure[i].checkPoint,
          intermediateProcedure[i].acceptanceCriteria,
          intermediateProcedure[i].frequency
        )
      );
    }

    setListData(rows);
  };

  useEffect(() => {
    initiatIntermediateProcedureList();
  }, [intermediateProcedure, standardData]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{ justifyContent: "center" }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <List
            sx={{
              width: "100%",
              marginTop: "10%",
              maxWidth: 360,
              justifyContent: "center",
              bgcolor: "background.paper",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            <li>
              <ul>
                <ListSubheader>{`Standards`}</ListSubheader>
                {standardData.map((item, index) => (
                  <ListItem>
                    <ListItemText
                      primary={`${item.id}. ${item.standardName}`}
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          </List>
        </Modal>
      </div>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/createIntermediateProcedure"
          onClick={() => {
            {
            }
          }}
        >
          <b>Add New Intermediate procedure</b>
        </Button>
      </Toolbar>
      <hr />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Button onClick={handleOpen}>Open modal</Button>
                      <Button
                        style={{
                          borderColor: "#dc3545",
                        }}
                        component={Link}
                        to={`/master/editIntermediateProcedure/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>

                      <Button
                        onClick={(e) => {
                          if (
                            window.confirm("Really want to delete standard?")
                          ) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={listData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
