import React, { useEffect } from "react";
import { BANK_DETAILS, BASE_URL, GST_NUMBER } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HelpIcon from "@mui/icons-material/Help";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
  Tooltip,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import moment from "moment";
import "./viewInvoice.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
import { viewInvoiceGuide } from "./guide";
import { fetchAddresses } from "./productDetails";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
  },
});

function InstrumentTable(props) {
  const toWords = new ToWords();
  let productDetails = props.productDetails;
  let productList = props.productList;
  // console.log("Product Details " + props.productDetails);
  const hasDiscount =
    Array.isArray(productDetails) &&
    productDetails?.some((product) => product?.discount !== null);

  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
    discount: 0,
  };
  if (productDetails.length > 0) {
    for (let i = 0; i < productDetails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDetails[i]?.equipmentId
      );
      productDetails[i].name = result?.instrumentName;
      let totalAmount = productDetails[i].rate * productDetails[i].quantity;
      let discountAmount =
        (totalAmount * (parseFloat(productDetails[i]?.discount) || 0)) / 100;
      productDetails[i].amount = totalAmount - discountAmount;
      amounts.totalAmountBeforeTax += productDetails[i]?.amount;
    }
    amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;

    amounts.conveyanceCharges = parseInt(
      props.invoiceAllData?.conveyanceCharges
        ? props.invoiceAllData?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      props.invoiceAllData?.courierCharges
        ? props.invoiceAllData?.courierCharges
        : "0"
    );

    amounts.totalAmountBeforeTax +=
      amounts.conveyanceCharges + amounts.courierCharges;

    amounts.discount = props.invoiceAllData?.discount;
    let discountcal = (amounts.totalAmountBeforeTax * amounts.discount) / 100;

    amounts.totalAmountBeforeTax = amounts.totalAmountBeforeTax - discountcal;

    const gstType = props.invoiceAllData?.gstType;
    const gstPercentages = props.invoiceAllData?.gstpercentages;

    if (gstType === 1) {
      const percentage = gstPercentages ? gstPercentages / 2 : 9;
      amounts.sgst = amounts.cgst =
        (amounts.totalAmountBeforeTax * percentage) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (gstType === 2) {
      amounts.gst =
        (amounts.totalAmountBeforeTax * (gstPercentages || 18)) / 100;
    } else {
      amounts.gst = 0;
    }

    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
  }
  return (
    <Table mt={5} className={[props.classes.table]}>
      <TableHead>
        <TableRow id="pagbreaksborder">
          <TableCell
            className={[props.classes.srCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Sr. No</b>
          </TableCell>
          <TableCell
            className={[props.classes.nameCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Description of Item</b>
          </TableCell>
          <TableCell
            className={[props.classes.makeCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Description</b>
          </TableCell>
          <TableCell
            className={[props.classes.makeCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>SAC | HSN Code Job Type</b>
          </TableCell>
          <TableCell
            className={[props.classes.makeCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Type of Service</b>
          </TableCell>
          <TableCell
            className={[props.classes.rangeCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Quantity</b>
          </TableCell>
          <TableCell
            className={[props.classes.qntCell, "special2"]}
            align="center"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            <b>Rate</b>
          </TableCell>
          {hasDiscount && (
            <TableCell
              className={[props.classes.qntCell, "special2"]}
              align="center"
              style={{ borderBottom: "none", borderRight: "none" }}
            >
              <b>Discount</b>
            </TableCell>
          )}
          <TableCell
            className={[props.classes.rateCell, "special2"]}
            align="center"
            style={{ borderBottom: "none" }}
          >
            <b>Amount</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="single-border-table">
        {productDetails.length > 0 &&
          productDetails?.map((product, i) => (
            <TableRow
              className={props.classes.row}
              style={{ pageBreakInside: "avoid" }}
              id="pagbreaksborder"
              key={i}
            >
              <TableCell className={props.classes.srCell} align="center">
                {i + 1}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="center">
                {product?.name}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="center">
                {product?.description}
              </TableCell>
              <TableCell
                className={props.classes.nameCell}
                align="center"
                // style={{ minWidth: "170px" }}
              >
                {product?.hsnCode}
              </TableCell>
              <TableCell
                className={props.classes.nameCell}
                align="center"
                // style={{ minWidth: "100px" }}
              >
                {product?.service}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product?.quantity}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product?.rate.toFixed(2)}
              </TableCell>
              {hasDiscount ? (
                product?.discount !== null ? (
                  <TableCell className={props.classes.rateCell} align="center">
                    {product?.discount} %
                  </TableCell>
                ) : (
                  <TableCell
                    className={props.classes.rateCell}
                    align="center"
                  ></TableCell>
                )
              ) : null}
              <TableCell className={props.classes.rateCell} align="center">
                {product?.amount.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        {amounts.conveyanceCharges !== 0 && (
          <TableRow id="pagbreaksborder">
            <TableCell
              className={props.classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b>Onsites Charges &nbsp;</b>
            </TableCell>
            <TableCell className={props.classes.rateCell} align="center">
              <b>
                {" "}
                {amounts.conveyanceCharges
                  ? amounts.conveyanceCharges.toFixed(2)
                  : 0}
              </b>
            </TableCell>
          </TableRow>
        )}
        {amounts.courierCharges !== 0 && (
          <TableRow id="pagbreaksborder">
            <TableCell
              className={props.classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b> Courier Charges &nbsp;</b>
            </TableCell>
            <TableCell className={props.classes.rateCell} align="center">
              <b>
                {amounts.courierCharges ? amounts.courierCharges.toFixed(2) : 0}
              </b>
            </TableCell>
          </TableRow>
        )}
        {amounts?.discount ? (
          <TableRow id="pagbreaksborder">
            <TableCell
              className={props.classes.nameCell}
              colSpan={hasDiscount ? 8 : 7}
              align="right"
            >
              <b>Discount&nbsp;</b>
            </TableCell>
            <TableCell className={props.classes.rateCell} align="center">
              <b>{amounts?.discount ? `${amounts?.discount} %` : ""}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        <TableRow id="pagbreaksborder">
          <TableCell
            className={props.classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b>Total Amount &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts?.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>

        {props.invoiceAllData?.gstType === 1 && (
          <>
            <TableRow id="pagbreaksborder">
              <TableCell
                className={props.classes.nameCell}
                colSpan={hasDiscount ? 8 : 7}
                align="right"
              >
                <b>{`CGST(${(props.invoiceAllData?.gstpercentages || 18) / 2}%) `}</b>
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                <b>{(amounts?.gst/ 2)?.toFixed(2)}</b>
              </TableCell>
            </TableRow>
            <TableRow id="pagbreaksborder">
              <TableCell
                className={props.classes.nameCell}
                colSpan={hasDiscount ? 8 : 7}
                align="right"
              >
                <b>{`SGST(${(props.invoiceAllData?.gstpercentages || 18) / 2}%) `}</b>
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                <b>{(amounts?.gst / 2)?.toFixed(2)}</b>
              </TableCell>
            </TableRow>
          </>
        )}
        {(props.invoiceAllData?.gstType === 2 ||
          props.invoiceAllData?.gstType === 3) && (
          <>
            <TableRow id="pagbreaksborder">
              <TableCell
                className={props.classes.nameCell}
                colSpan={hasDiscount ? 8 : 7}
                align="right"
              >
                <b>{`IGST(${props.invoiceAllData?.gstpercentages || 18}%) `}</b>
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                <b>{amounts.gst?.toFixed(2)}</b>
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow style={{ backgroundColor: "#D9D9D9" }} id="pagbreaksborder">
          <TableCell
            className={props.classes.nameCell}
            colSpan={hasDiscount ? 8 : 7}
            align="right"
          >
            <b>Total Amount &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts?.grandTotal ? amounts?.grandTotal.toFixed(2) : ""}</b>
          </TableCell>
        </TableRow>
        <TableRow id="pagbreaksborder">
          <TableCell
            className={props.classes.nameCell}
            colSpan={hasDiscount ? 9 : 8}
            align="left"
          >
            <div>
              <b style={{ backgroundColor: "#D9D9D9", padding: "1px 5px" }}>
                &nbsp; Amount in Words:
              </b>
              <b>
                &nbsp;
                {toWords.convert(
                  amounts?.grandTotal ? amounts?.grandTotal.toFixed(2) : 0,
                  { currency: true }
                )}
              </b>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default function ViewCustomInvoice() {
  const viewInvoiceGuides = viewInvoiceGuide();
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState({});
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [invoiceData, setInvoiceData] = React.useState({});
  const [gstType, setGstType] = React.useState(2);
  const [letterHead, setLetterHead] = React.useState(true);
  const [printTitle, setPrintTitle] = React.useState(true);
  const [invoiceAllData, setinvoiceAllData] = React.useState([]);
  const [clientInfo, setClientInfo] = React.useState({
    contact: "",
    gstNumber: "",
  });
  const [state, setState] = React.useState({
    partiallyNumbers: ["Final", 1],
    breakupId: "Final",
  });
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [formatType, setFormatType] = React.useState("");
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL();
    document.body.removeChild(div);
    return img;
  };
  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [88, 1, 60, 1],
          filename: `certificate_${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

         // border add for contnet
         const borderWidth = 0.6;
         const leftMargin = 15;
         const borderX = leftMargin;
         const borderY = 85;
         const rightMargin = 595 - leftMargin * 0.95;
         pdf.setLineWidth(borderWidth);
         pdf.rect(borderX, borderY, rightMargin - leftMargin, 690);

        // let image = await generateDynamicHeader(
        //   ulrNumber,
        //   certificateNumber,
        //   i,
        //   totalPages
        // );
        // pdf.addImage(image, 0, 70, 595, 55);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 50, 595, 55);

        if (letterHead) {
          let image = await generateLetterHeadHeader();

          pdf.addImage(image, 0, 0, 595, 70);

          image = await generateLetterHeadFooter();
          pdf.addImage(image, 0, 785, 595, 58);
        }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${invoiceData?.client_companyName}_${invoiceAllData?.invoiceNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  const fetchSettings = () => {
    // Terms and Condition
    let url = BASE_URL;
    axiosWithToken
      .get(url + "settings?_where=(keyName,eq,Invoice Terms)&_fields=value")
      .then((res) => {
        if (res.data.length > 0) setTermAndCondition(res.data[0].value);
      })
      .catch((err) => {
        console.log(err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"View Invoice")}><u>Error: {err.message}</u></h6>);
      });
  };
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {        
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"View Invoice")}><u>Error: {err.message}</u></h6>);
      });
  };
  const getInvoiceData = () => {
    let payload = {
      query: `SELECT pMaster.id AS pMaster_id, pMaster.discount AS pMaster_discount, invoice.invoiceDate AS invoice_invoiceDate, invoice.gstType AS invoice_gstType, invoice.challanNo AS invoice_challanNo, invoice.challanDate AS invoice_challanDate, invoice.ourChallanNo AS invoice_ourChallanNo, invoice.ourChallanDate AS invoice_ourChallanDate, invoice.invoiceType AS invoice_invoiceType, client.companyName AS client_companyName, invoice.clientId AS invoice_clientId, client.address AS client_address, client.contact AS client_contact, client.gstNumber AS client_gstNumber, invoice.poNumber AS invoice_poNumber, invoice.poDate AS invoice_poDate, invoice.subject AS invoice_subject FROM customInvoice AS invoice LEFT JOIN clients AS client ON invoice.clientId = client.id LEFT JOIN paymentMaster AS pMaster ON invoice.poNumber = pMaster.poNumber WHERE invoice.id =${params.mainId} AND invoice.status = 1`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        let tmp = res.data[0];
        setInvoiceData(tmp);
        setGstType(tmp?.invoice_gstType);
        setClientInfo({
          contact: tmp?.client_contact
            ? JSON.parse(tmp.client_contact)[0]
            : null,
          gstNumber: tmp?.client_gstNumber || "",
        });
      })
      .catch((err) => {
        console.log("error!", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getInvoiceData" ,"View Invoice")}><u>Error: {err.message}</u></h6>);
      });
  };
  const fetchInvoiceDetails = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `customInvoice/${params.mainId}`)
      .then(async (res) => {
        let invoiceData = res.data[0];
        setinvoiceAllData(invoiceData);
        // if (invoiceData.productDetails) {
        //   setProductDetails(
        //     invoiceData.productDetails == ""
        //       ? ""
        //       : JSON.parse(invoiceData.productDetails)
        //   );
        // }
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 2 AND referenceId = ${
            res.data[0]?.id
          } ${
            state?.breakupId != "Final"
              ? `AND breakupId = ${state?.breakupId}`
              : ""
          }`,
        };
        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        let allProducts = response.data;
        const mergedProducts = Object.values(
          allProducts.reduce((acc, obj) => {
            const key = obj.rate + "_" + obj.equipmentId;
            if (acc[key]) {
              acc[key].quantity += obj.quantity;
            } else {
              acc[key] = { ...obj };
            }
            return acc;
          }, {})
        );
        setProductDetails(invoiceData?.quantityMerge == 1 ? mergedProducts : allProducts);

        let sqlQuery1 = {
          query: `SELECT DISTINCT breakupId FROM productDetails WHERE type = 2 AND referenceId = ${res.data[0]?.id}`,
        };

        const response1 = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery1
        );
        const breakupIdsArray = response1.data
          .map((item) => item.breakupId)
          ?.filter((id) => id !== 1);

        setState((prevState) => {
          const uniqueNumbers = new Set([
            ...prevState.partiallyNumbers,
            ...breakupIdsArray,
          ]);
          return {
            ...prevState,
            partiallyNumbers: Array.from(uniqueNumbers),
          };
        });
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchInvoiceDetails" ,"View Invoice")}><u>Error: {err.message}</u></h6>);
      });
  };

  useEffect(async () => {
    let splitAddress = invoiceAllData?.address?.split(",");
    let newAddressArray = await fetchAddresses(
      splitAddress?.[0],
      splitAddress?.[1]
    );
    setNewAddressArray(newAddressArray);
  }, [invoiceAllData?.address]);

  useEffect(() => {
    // getCustomReadingsArray();
    fetchSettings();
    getProductList();
    getInvoiceData();
  }, []);
  useEffect(() => {
    fetchInvoiceDetails();
  }, [params.mainId, state.breakupId]);

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                viewInvoiceGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
        <Grid
          container
          spacing={2}
          align="left"
          justifyContent="flex-end"
          style={{ width: "95%", margin: "auto" }}
        >
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="invoice_view_Partially"
              options={state.partiallyNumbers}
              getOptionLabel={(option) => {
                if (option === "Final") {
                  return "Final Invoice";
                } else {
                  return `${option} Partially Invoice`;
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Partially Invoice" />
              )}
              onInputChange={(event, newInputValue) => {
                // console.log(newInputValue.trim().split(" ")[0])
                setState({
                  ...state,
                  breakupId: newInputValue.trim().split(" ")[0],
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="invoice_view_letterhead"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="invoice_view_printTitle"
              options={[
                { id: 1, label: "With Tax Invoice" },
                { id: 2, label: "With Proforma Invoice" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Print Title" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Tax Invoice" ||
                  newInputValue === ""
                ) {
                  setPrintTitle(true);
                } else if (newInputValue === "With Proforma Invoice") {
                  setPrintTitle(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              // id="invoice_view_printTitle"
              options={[
                { id: 1, label: "ORIGINAL FOR RECIPIENT" },
                { id: 2, label: "DUPLICATE FOR SUPPLIER" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Format Type" />
              )}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === "ORIGINAL FOR RECIPIENT") {
                  setFormatType("ORIGINAL FOR RECIPIENT");
                } else if (newInputValue === "DUPLICATE FOR SUPPLIER") {
                  setFormatType("DUPLICATE FOR SUPPLIER");
                } else {
                  setFormatType("");
                }
              }}
            />
          </Grid>
          <Grid item xs={3} align="right">
            <Button
              id="invoice_view_Download"
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <div
          className="invoice"
          ref={printComponentRef}
          id="invoice_view__print"
        >
          <div
            style={{
              width: "95%",
              // border: "1px solid black",
              textAlign: "center",
              margin: "5px auto",
              padding: "10px",
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid md={4} className="font-link" align="center"></Grid>
              <Grid md={4} className="font-link" align="center">
                <h5 style={{ color: "#000066", margin: "10px 0px" }}>
                  <b> {printTitle ? "Tax Invoice" : "Proforma Invoice"}</b>
                </h5>
              </Grid>
              <Grid md={4} align="center">
                <span>{formatType}</span>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        width: "25%",
                        borderRight: "none",
                      }}
                    >
                      <b>Invoice No</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ width: "25%", borderRight: "none" }}
                    >
                      {invoiceAllData?.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                        width: "25%",
                      }}
                    >
                      <b>Invoice Date</b>
                    </TableCell>
                    <TableCell align="left" style={{ width: "25%" }}>
                      {moment(invoiceAllData?.invoiceDate).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        borderRight: "none",
                        width: "50%",
                      }}
                    >
                      <b>To,</b>
                      <br />
                      {invoiceData?.client_companyName}
                      <br />
                      {newAddressArray?.[0]?.address ? (
                        <b>Billing Address :</b>
                      ) : (
                        ""
                      )}
                      <br />
                      {newAddressArray?.[0]?.address}
                      <br />
                      {newAddressArray?.[0]?.shipping?.address ? (
                        <b>Shipping Address :</b>
                      ) : (
                        ""
                      )}
                      <br />
                      {newAddressArray?.[0]?.shipping?.address}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                        width: "25%",
                      }}
                    >
                      <b>
                        Customer Challan No.
                        <br />
                        Customer Challan Date.
                        <br />
                        Our Challan No.
                        <br />
                        Our Challan Date.
                        <br />
                        P.O. No.
                        <br />
                        P.O. Date.
                      </b>
                    </TableCell>
                    <TableCell align="left" style={{ width: "25%" }}>
                      {invoiceData?.invoice_challanNo}
                      <br />
                      {moment(invoiceData?.invoice_challanDate).format(
                        "DD/MM/YYYY"
                      )}
                      <br />
                      {invoiceData?.invoice_ourChallanNo}
                      <br />
                      {moment(invoiceData?.invoice_ourChallanDate).format(
                        "DD/MM/YYYY"
                      )}
                      <br />
                      {invoiceData?.invoice_poNumber}
                      <br />
                      {moment(invoiceData?.invoice_poDate).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                        width: "25%",
                      }}
                    >
                      <b>GST NO</b>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ width: "25%", borderRight: "none" }}
                    >
                      {clientInfo?.gstNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                        width: "25%",
                      }}
                    >
                      <b>Reference Number</b>
                    </TableCell>
                    <TableCell align="left" style={{ width: "25%" }}>
                      {invoiceAllData?.referenceNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                      }}
                    >
                      <b>Kind Attn</b>
                    </TableCell>
                    <TableCell align="left" style={{ borderRight: "none" }}>
                      {clientInfo?.contact?.contactPersonName}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "none",
                      }}
                    >
                      <b>Payment</b>
                    </TableCell>
                    <TableCell align="left">100% Against Delivery </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <InstrumentTable
              classes={classes}
              productDetails={productDetails}
              gstType={gstType}
              productList={productList}
              invoiceAllData={invoiceAllData}
            ></InstrumentTable>

            <div style={{ pageBreakInside: "avoid", paddingTop: "2px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  margin: "0px auto",
                  border: "1px solid black",
                  fontSize: "0.875rem",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    borderRight: "1px solid black",
                    padding: "5px",
                    width: "50%",
                  }}
                >
                  <b>Bank Details For Payment - </b>
                  <br />
                  {BANK_DETAILS["Bank Name"]}
                  <br />
                  <b>Bank A/c. No. :</b>
                  {BANK_DETAILS["Bank A/c. No."]}
                  <br />
                  <b>IFSC Code :</b>
                  {BANK_DETAILS["IFSC Code"]}
                  <br />
                  <b>GST NO :</b>
                  {GST_NUMBER}
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <b>For BFI , Mumbai</b>
                  <b>Authorized Signatory</b>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "left", fontSize: "0.875rem" }}>
              <b>Note :-</b>
              <span>
                Material return as it after calibration with calibration
                certificates - Yes / No
              </span>
            </div>
          </div>

          <div style={{ width: "100%" }} id="header-section"></div>
        </div>
        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Invoice"}
          />
        )}
          {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
      </Paper>
    </>
  );
}
