import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function CreateDispatch() {
  const [companyName, setCompanyName] = React.useState();
  const [address, setAddress] = React.useState();
  const [instruments, setInstruments] = React.useState();
  const [dispatchMedium, setDispatchMedium] = React.useState();
  const [dispatchDate, setDispatchDate] = React.useState();
  var [clientArray, setClientArray] = React.useState([]);
  var [standardArray, setStandardArray] = React.useState([]);
  const [customerList, setCustomerList] = React.useState([]);
  const [standardList, setStandardList] = React.useState([]);

  const getClientList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const generateCustomerList = (clientArray) => {
    const newCustomerList = clientArray.map((client) => ({
      id: client.id,
      label: client.companyName,
      address: client.address,
    }));
    return newCustomerList;
  };

  const getInstrumentList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setStandardArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const generateStandardList = (standardArray) => {
    const newStandardList = standardArray.map((standard) => ({
      id: standard.id,
      label: standard.instrumentName,
    }));
    return newStandardList;
  };

  useEffect(() => {
    setStandardList(generateStandardList(standardArray));
  }, [standardArray]);

  useEffect(() => {
    setCustomerList(generateCustomerList(clientArray));
  }, [clientArray]);

  useEffect(() => {
    getClientList();
    getInstrumentList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      companyName: companyName,
      address: address,
      instruments: instruments,
      dispatchMedium: dispatchMedium,
      dispatchDate: moment(dispatchDate).format("YYYY-MM-DD"),
    };
    console.log(row);
    let url = BASE_URL;
    axiosWithToken
      .post(url + "dispatch", row)
      .then((res) => {
        toast("dispatch created successfully !");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Dispatch
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={customerList}
            renderInput={(params) => <TextField {...params} label="Client *" />}
            onChange={(event, value) => {
              if (value != null) {
                setCompanyName(value.label);
                setAddress(value.address);
              } else {
                setCompanyName("");
                setAddress("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={standardList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Standards"
                placeholder="standards"
                style={{ whiteSpace: "nowrap" }}
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.label;
                  })
                  .join(",");
                setInstruments(arrToStr);
                console.log(instruments);
              } else {
                setInstruments("");
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="dispatch Medium *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDispatchMedium(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="dispatch date "
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDispatchDate(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
