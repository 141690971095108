import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Stack,
  Tooltip,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router";
import HelpIcon from "@mui/icons-material/Help";
import { createDocumentGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const CreateDocument = () => {
  const createDocumentGuides = createDocumentGuide();
  const navigate = useNavigate();
  const { id } = useParams();
  const [doclist, setDoclist] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  const getDocumentData = () => {
    if (id !== "0") {
      axiosWithToken
        .get(BASE_URL + `documentUploader/${id}`)
        .then((res) => {
          setDoclist(res.data);
          setSelectedDocument(res.data[0].documentName);
          setFileUploadResponse(res.data[0].documentURL);
        })
        .catch((error) => {
          toast.error("Something Went Wrong while fetching standardRanges!");
        });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setFileUploadResponse(res.data);
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  const createDocument = async () => {
    try {
      const payload = {
        documentName: selectedDocument,
        documentURL: fileUploadResponse,
      };

      await axiosWithToken.post(BASE_URL + "documentUploader", payload);
      toast.success("Document Upload Successful");
      navigate("/documentUpload");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const updateDocument = async () => {
    try {
      const payload = {
        documentName: selectedDocument,
        documentURL: fileUploadResponse,
      };

      await axiosWithToken.patch(BASE_URL + `documentUploader/${id}`, payload);
      toast.success("Document Update Successful");
      navigate("/documentUpload");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = async () => {
    if (!selectedDocument.trim("") || !fileUploadResponse) {
      toast.error("Please select a document and upload a file.");
      return;
    }

    if (id === "0") {
      createDocument();
    } else {
      updateDocument();
    }
  };

  useEffect(() => {
    getDocumentData();
  }, [id]);

  return (
    <div>
      <Box sx={{ margin: "20px", textAlign: "left" }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
        <Typography variant="h6">Document Upload</Typography>
      <Tooltip title="User Guide" placement="top-start">
            <Button
            onClick={() => {
              createDocumentGuides.drive();
            }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>  
        <Stack direction="row" spacing={5} alignItems="center" sx={{ my: 3 }}>
          <FormControl variant="standard" sx={{ minWidth: 200 }} id="create_document_select">
            <InputLabel id="demo-simple-select-standard-label">
              Document Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Document Name"
              value={selectedDocument || ""}
              onChange={(e) => setSelectedDocument(e.target.value)}
            >
              <MenuItem value={"NABL Scope"}>NABL Scope</MenuItem>
              <MenuItem value={"NABL Certificate "}>NABL Certificate</MenuItem>
              <MenuItem value={"Company Brochure "}>Company Brochure</MenuItem>
              <MenuItem value={"Marketing Manual "}>Marketing Manual</MenuItem>
            </Select>
          </FormControl>
          <Button
          id="create_document_uploadfile"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{ mt: 5 }}
          >
            { fileUploadResponse == null ? "Upload file" : fileUploadResponse.split("/").pop()}
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
          </Button>
        </Stack>

        <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem", }}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
        id="create_document_save"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Save
        </Button>
      </Toolbar>

      </Box>
    </div>
  );
};
export default CreateDocument;
