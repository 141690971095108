import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { auditListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";


export default function AuditTable() {
  const auditListGuides = auditListGuide();
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [auditData, setAuditData] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [usersArray, setUsersArray] = React.useState([]);
  const [allUsers, setUsers] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("User Name");
  const [searchKey, setSearchKey] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [clientId, setClientId] = React.useState(
    localStorage.getItem("clientId")
  );

  const columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 50,
    },
    {
      id: "type",
      label: "Type",
      align: "left",
      minWidth: 150,
    },
    {
      id: "identifier",
      label: "Identifier",
      align: "left",
      minWidth: 150,
    },
    {
      id: "user",
      label: "User",
      align: "left",
      minWidth: 200,
    },
    {
      id: "companyName",
      label: "Company",
      align: "left",
      minWidth: 200,
    },
    {
      id: "time",
      label: "Date and Time",
      align: "left",
      minWidth: 200,
    },
    {
      id: "action",
      label: "Action",
      align: "left",
      minWidth: 100,
    },
    {
      id: "remarks",
      label: "Remarks",
      align: "left",
      minWidth: 100,
    },
  ];
  const IDS = auditData.map((item) => item.id);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions = ""; //"where status = 1 "
    let pagination_settings = "";
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Type: "al.type",
        "User Name": "u.userName",
        Client: "c.companyName",
      }[searchBy];
      whereConditions += `where ${colName} like '%${searchKey}%' `;
    }

    if (to != null && from != null) {
      whereConditions += ` and change_date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (userType == 3) {
      whereConditions +=
        (whereConditions === "" ? " where " : " and ") +
        `a.company = ${clientId}`;
    }

    let data = {
      query: `SELECT al.*, c.companyName, u.userName FROM audit_log al LEFT JOIN clients c ON al.clientId = c.id LEFT JOIN users u ON al.userId = u.id ${whereConditions} order by al.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(al.id) as count FROM audit_log al LEFT JOIN clients c ON al.clientId = c.id LEFT JOIN users u ON al.userId = u.id ${whereConditions} order by al.id DESC ${pagination_settings}`;

    return data;
  };

  function fetchAudit() {
    let data = getQuery();
    let data_count = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setAuditData([...res.data]);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
    axiosWithToken
      .post(BASE_URL + `dynamic`, data_count)
      .then((res) => setTotalRows(res.data[0]?.count ? res.data[0]?.count : 0));
  }

  const getUserList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "users?_where=(type,eq,2)")
      .then((res) => {
        setUsersArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initializeuserData = () => {
    var pushCArray = [];
    for (let i = 0; i < usersArray.length; i++) {
      pushCArray.push({
        label: usersArray[i].id + ", " + usersArray[i].userName,
      });
    }
    setUsers(pushCArray);
  };

  const search = () => {
    fetchAudit();
  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    initializeuserData();
  }, [usersArray]);

  useEffect(() => {
    fetchAudit();
  }, [rowsPerPage, page]);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <>
    <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          auditListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <div
          className="mb-2"
          style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
        >
          <div style={{ marginLeft: "10px", marginRight: "10px" }} id="audit_list_datefrom">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
              slotProps={{ textField: { size: "small" ,fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }} id="audit_list_dateto">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
              slotProps={{ textField: { size: "small" ,fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div>

          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="audit_list_searchby"
            value={searchBy}
            options={[
              { key: "user", label: "User Name" },
              { key: "type", label: "Type" },
              { key: "c.companyName", label: "Client" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="audit_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            sx={{marginLeft:"10px"}}
          />
          <Button
          id="audit_list_searchbtn"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
          <Link to={`/audit/viewLogs?id=${JSON.stringify(IDS)}`}>
            <Button
            id="audit_list_printall"
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
            >
              <PrintIcon />
            </Button>
          </Link>
        </div>

        <div style={{ margin: "10px" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            class="table-responsive"
            size="small"
            id="audit_list_table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {auditData &&
                auditData.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>
                        {rowsPerPage * Number(page) + index + 1}
                      </TableCell>

                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.identifier}</TableCell>
                      <TableCell>{row.userName}</TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>
                        {moment(row.change_date).format("DD/MM/yyy HH:mm:ss")}
                      </TableCell>
                      <TableCell>{row.action}</TableCell>

                      <TableCell>
                        <Link
                          to={`/audit/viewLogs?id=${JSON.stringify([
                            row.id,
                          ])}`}
                        >
                          <Button
                          id="audit_list_download"
                            variant="contained"
                            size="small"
                            sx={{ m: 0 }}
                            style={{ marginLeft: "10px" }}
                          >
                            <RemoveRedEyeIcon />
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            colSpan={3}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
          />
          <ToastContainer />
        </div>
      </TableContainer>
    </>
  );
}
