export const initialState = {
  datasheetDetails: {
    id: 0,
    instrumentId: 0,
    ranges: "",
    lc: "",
    range_low: "",
    range_high: "",
    accuracy: "",
    tableDisplayStatus: 1,
  },

  certificateDetails: {
    certificateNumber: "",
    ULRNumber: "",
  },

  disciplineDetails: {
    documentNumber: "",
    issueNo: "",
    issueDate: "",
    amendmentNo: "",
    amendmentDate: "",
  },

  srfDetails: {
    srfNumber: "",
    entryDate: "",
  },
  standardMasterDetails: {
    standardMasterIds: "",
  },

  instrumentDetails: null,

  // master data
  units: [],
  unitSymbols: [],
  standardMasterArray: [],
  standardRanges: null,
  supportiveStandardMasterArray: [],

  // referenced data
  referenceData: {},

  // data associated with observed readings
  datasheetStaticReadings: [],
  certificateStaticReadings: [],
  staticReadingRows: [],
  selectedStandardIds: [],
  selectedStandardMasters: [],
  selectedStandards: [],
  selectedSupportiveRanges: [],
  staticTables: null,
  certificateStaticTables: [],

  // indicators
  indicators: {
    complianceStatus: true,
    isShowAmendment: false,
    isCalTypeSetting: false,
    outOffRangeTracker: {}
  },

//OpinionAndAnalysisTable
  analysisSelectedOption:{
    accepted: '',
    calibrated: '',
    limitedUse: '',
    rejected: '',
  },

  //isProcess
  isProcess:false,

  // miscellanous
  calibratedBy: "",
  approvedBy: "",
  calibratedByImgSign: "",

  DatasheetReadingsAdditionalInfo:[],

};
