import { toast } from "react-toastify/dist";
import { BASE_URL } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export const getDiscipline = async () => {
  let url = BASE_URL;
  try {
    const response = await axiosWithToken.get(
      url + "discipline?_where=(status,eq,1)"
    );
    return response.data;
  } catch (error) {
    toast.error("Something Went Wrong while fetching disciplines!");
    throw error;
  }
};

export const getUnitMasterArray = async () => {
  let url = BASE_URL;
  try {
    const response = await axiosWithToken.get(
      url + "unit?_where=(status,eq,1)"
    );
    return response.data;
  } catch (error) {
    toast.error("Something Went Wrong while fetching units!");
    throw error;
  }
};

export const getRanges = async (paramsId, page, rowsPerPage) => {
  try {
    const totalCountResponse = await axiosWithToken.get(
      BASE_URL +
        `standardRanges/count?_where=(standardId,eq,${paramsId})~and(status,eq,1)&_p=${page}&_size=${rowsPerPage}`
    );
    const totalCount = totalCountResponse.data[0]?.no_of_rows;

    const standardRangesResponse = await axiosWithToken.get(
      BASE_URL +
        `standardRanges?_where=(standardId,eq,${paramsId})~and(status,eq,1)&_sort=id&_p=${page}&_size=${rowsPerPage}`
    );
    const standardRanges = standardRangesResponse.data;

    return { totalCount, standardRanges };
  } catch (error) {
    toast.error("Something Went Wrong while fetching standardRanges!");
    throw error;
  }
};

export const getStandard = (
  paramsId,
  unitArray,
  standardDetails,
  setStandardDetails,
  createSrfObject,
  setDeciplines,
  setPercent,
  setPlus,
  setMnv,
  setUnits
) => {
  let url = BASE_URL;

  axiosWithToken
    .get(
      url +
        `xjoin?_join=ds.standards,_j,disciplines.discipline,&_on1=(ds.disciplineId,eq,disciplines.id)&_fields=ds.location,ds.materialType,ds.masterrange,ds.masteraccuracy,ds.masterleastcount,ds.standardName,ds.type,ds.make,ds.id,ds.certificateUrl,ds.traceability,ds.validUpto,ds.id,ds.certificateNumber,disciplines.name,ds.model,ds.serialNo,ds.stId,ds.calDate,disciplines.id,disciplines.disciplineKey&_where=(ds.id,eq,${paramsId})~and(ds.status,eq,1)`
    )
    .then((res) => {
      setStandardDetails({
        ...standardDetails,
        standardName: res.data[0]?.ds_standardName || "",
        ids: res.data[0]?.ds_stId || "",
        serialNo: res.data[0]?.ds_serialNo || "",
        make: res.data[0]?.ds_make || "",
        model: res.data[0]?.ds_model || "",
        location: res.data[0]?.ds_location || "",
        calDate: res.data[0]?.ds_calDate || "",
        validUpto: res.data[0]?.ds_validUpto || "",
        traceability: res.data[0]?.ds_traceability || "",
        certificateNumber: res.data[0]?.ds_certificateNumber || "",
        EQMType: res.data[0]?.ds_type || "",
        materialType: res.data[0]?.ds_materialType || "",
        certificateUrl: res.data[0]?.ds_certificateUrl || "",
      });
      let dataObject = res.data[0];
      let newData = {
        masterrange: dataObject.ds_masterrange,
        masteraccuracy: dataObject.ds_masteraccuracy,
        masterleastcount: dataObject.ds_masterleastcount,
      };
      dataObject && createSrfObject(newData);

      setDeciplines(
        res.data[0]?.disciplines_id + ", " + res.data[0]?.disciplines_name
      );
      let toggle = [true, true, true, true];
      if (res.data[0]?.disciplines_disciplineKey == "0") {
        toggle[0] = false;
        toggle[1] = false;
      } else if (res.data[0]?.disciplines_disciplineKey == "2") {
        toggle[3] = false;
      }
      setPercent(toggle[0]);
      setPlus(toggle[1]);
      setMnv(toggle[3]);

      const unitArr = [""];
      for (let i = 0; i < unitArray?.length; i++) {
        if (
          parseInt(res.data[0]?.disciplines_id) == unitArray[i]?.disciplineId
        ) {
          unitArr.push(unitArray[i]);
        }
      }
      setUnits(unitArr);
    })
    .catch((error) => {
      console.log(error);
      toast.error("Something Went Wrong with standards!");
    });
};