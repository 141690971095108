import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Grid, TextField, Autocomplete } from "@mui/material";
import { templateMasterListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "50% !important",
  height: "40% !important",
};



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "tableName",
    label: "Table Name",
    align: "left",
    minWidth: 40,
  },

  // {
  //   id: "unitType",
  //   label: "Unit Type",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(id, instrumentName, tableName, unitType) {
  return {
    id,
    instrumentName,
    tableName,
    unitType,
  };
}

export function TablesList() {
  const templateMasterListGuides = templateMasterListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Instrument Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [clientList, setClientsList] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [staticDsTableArray, setDSStaticTableArray] = React.useState([]);
  const [certStaticTableArray, setCertStaticTableArray] = React.useState([]);
  const [tableName, setTableName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowDaata, setRowData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const [pushIArray, setPushIArray] = React.useState([]);
  const ref = useRef(null);
  const handleClose = () => setOpen(false);
  const handleOpen = (row) => {
    setTableName(row.tableName);
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetStaticTables?_where=(id,eq,${row.id})`)
      .then((res) => {
        setDSStaticTableArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });

    axiosWithToken
      .get(url + `certificateStaticTables?_where=(id,eq,${row.id})`)
      .then((res) => {
        setCertStaticTableArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });

    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    setSearched(true);
    getInstrumentsList();
    getClientList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false); 
  };

  const getClientList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Instrument Name": "instrumentName",
        "Table Name": "tableName",
      }[searchBy];

      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `select pl.instrumentId,pr.instrumentName,pl.tableName,pl.unitType,pl.id from datasheetStaticTables as pl join instruments as pr on pl.instrumentId = pr.id ${whereConditions} order by pl.id DESC `,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setClientsList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });
  };

  const submitSiteDelete = async (id) => {
    const url = BASE_URL;

    try {
      await axiosWithToken.delete(`${url}datasheetStaticTables/${id}`);
      
    } catch (error) {
      toast.error("Error deleting template table!");
    }

    try {
      await axiosWithToken.delete(`${url}certificateStaticTables/${id}`);
      toast("Template table deleted!");
    } catch (error) {
      toast.error("Error deleting certificate table!");
    }

      try {
        await axiosWithToken.post(`${url}dynamic`, {
          query: `DELETE FROM datasheetStaticReadings WHERE tableId = ${id};`,
        });
      } catch (error) {
        toast.error("Error deleting datasheet static readings!");
      }

      try {
        await axiosWithToken.post(`${url}dynamic`, {
          query: `DELETE FROM certificateStaticReadings WHERE tableId = ${id};`,
        });
        setTimeout(refresh, 500);
      } catch (error) {
        toast.error("Error deleting certificate static readings!");
      }
    
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const generateRowData = (clientList1) => {
    const newRowData = clientList?.map((client) => {
      return createData(
        client?.id,
        client?.instrumentName,
        client?.tableName,
        client?.unitType
      );
    });

    setRowData(newRowData);
  };

  const generatePushIArray = (instrumentArray) => {
    const newPushIArray = instrumentArray
      .filter((instrument) => instrument.instrumentName)
      .map((instrument) => {
        return {
          label: instrument.id + ", " + instrument.instrumentName,
        };
      });

    setPushIArray(newPushIArray);
  };
  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const handleSubmit = async (e) => {
    if (instrumentId != null) {
      var jsData = staticDsTableArray[0];
      var certData = certStaticTableArray[0];
      jsData.instrumentId = instrumentId;
      certData.instrumentId = instrumentId;
      delete jsData.id;
      delete jsData.lastModified;
      delete certData.id;
      delete certData.lastModified;
      let url = BASE_URL;
      axiosWithToken
        .post(url + "datasheetStaticTables", jsData)
        .then((res) => {
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something Went Wrong3!");
        });
      axiosWithToken
        .post(url + "certificateStaticTables", certData)
        .then((res) => {
          toast("Table copied successfully !");
          setTimeout(refresh, 500);
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something Went Wrong3!");
        });
    } else {
      toast.error("Please select atleast one instrument!");
    }
  };

  useEffect(() => {
    generateRowData(clientList);
    generatePushIArray(instrumentArray);
  }, [clientList, instrumentArray]);

  useEffect(() => {
    if (searched==false) {
    getClientList();
    getInstrumentsList();
    }
  }, [searched]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h5 className="text-center">
              <u>Table Name: {tableName}</u>
            </h5>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  options={
                    instrumentArray != null
                      ? instrumentArray.map((item) => {
                          return {
                            id: item.id,
                            instrumentName: item.instrumentName,
                          };
                        })
                      : []
                  }
                  getOptionLabel={(option) =>
                    option.id + ", " + option.instrumentName
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select Instrument" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    setInstrumentId(newInputValue.split(",")[0]);
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Toolbar style={{ padding: "0px", overflow: "auto" }}>
              <Button
                variant="contained"
                size="small"
                sx={{ m: 0 }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Toolbar>
          </Box>
        </Modal>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          <Button
            id="template_master_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/editStaticTable/0"
            onClick={() => {}}
          >
            <b>ADD NEW TEMPLATE</b>
          </Button>
        </Toolbar>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              templateMasterListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <hr />
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            value={searchBy}
            id="template_master_list_searchby"
            options={[
              { key: "instrumentName", label: "Instrument Name" },
              {
                key: "tableName",
                label: "Table Name",
              },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
          <TextField
            id="template_master_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value)
              setSearched(false);
            }}
            sx={{ marginLeft: "10px" }}
          />
          <Button
            id="template_master_list_searchbtn"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
           {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </div>
      </div>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="template_master_list_table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Template" placement="top-start">
                        <Button
                          id="template_master_list_edit"
                          component={Link}
                          to={`/master/editStaticTable/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Copy Template" placement="top-start">
                        <span id="template_master_list_copy">
                          <Button
                            id={row.id}
                            key={row.id}
                            ref={ref}
                            onClick={() => {
                              handleOpen(row);
                            }}
                          >
                            <ContentCopyIcon />
                          </Button>
                        </span>
                      </Tooltip>

                      <Tooltip title="Delete Template" placement="top-start">
                        <Button
                          id="template_master_list_delete"
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete Template?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ToastContainer />
    </TableContainer>
  );
}
