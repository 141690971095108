import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import { createUnitGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";

export default function CreateUnit(props) {
  const createUnitGuides = createUnitGuide();
  const [srfObject, createSrfObject] = React.useState({});
  const [disciplinesArray, setDisciplinesArray] = React.useState([]);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const getDisciplineList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setDisciplinesArray(res.data);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getDisciplineList" ,"Edit Unit")}><u>Error: {err.message}</u></h6>);
      });
  };

  useEffect(() => {
    getDisciplineList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    let row = {
      disciplineId: srfObject.disciplineId,
      symbol: srfObject.symbol,
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + "unit", row)
      .then((res) => {
        props.setLoader(false);
        toast("Unit created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        props.setLoader(false);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"Edit Unit")}><u>Error: {err.message}</u></h6>);
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create New Unit
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createUnitGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="unit_create_discipline"
            options={disciplinesArray.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="unit_create_symbol"
            label="Symbol *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("symbol", e.target.value);
            }}
          />
        </Grid>{" "}
      </Grid>
      <br />

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="unit_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Paper>
  );
}
