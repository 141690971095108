import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Toolbar,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
export default function UpdateCourier() {
  const [executing, setExecuting] = React.useState(false);
  var [client, setClient] = React.useState([]);
  var [date, setDate] = React.useState(null);
  var [workOrderNumber, setWorkOrderNumber] = React.useState("");
  var [place, setPlace] = React.useState("");
  var [courierPartner, setCourierPartner] = React.useState("");
  var [discription, setDiscription] = React.useState("");
  var [purpose, setPurpose] = React.useState("");
  var [trackingNo, setTrackingNo] = React.useState("");
  const [clientId, setClientId] = React.useState(null);
  const [clients, setClients] = React.useState([]);
  var params = useParams();

  const onSubmit = (event) => {
    setExecuting(true);

    let row = {
      date: moment(date).format("YYYY-MM-DD"),
      workOrderNumber: workOrderNumber,
      clientID: clientId,
      place: place,
      courierPartner: courierPartner,
      discription: discription,
      purpose: purpose,
      trackingNo: trackingNo,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `clientCourier/${params.id}`, row)
      .then((res) => {
        toast("Client Courier updated successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getExpenseList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clientCourier/${params.id}`)
      .then((res) => {
        setDate(res.data[0].date);
        setWorkOrderNumber(res.data[0].workOrderNumber);
        setPlace(res.data[0].place);
        setCourierPartner(res.data[0].courierPartner);
        setDiscription(res.data[0].discription);
        setPurpose(res.data[0].purpose);
        setTrackingNo(res.data[0].trackingNo);
        setClientId(res.data[0].clientID);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const generateClients = (client) => {
    const newClients = client.map((c) => {
      return {
        label: c.id + ", " + c.companyName,
      };
    });

    setClients(newClients);
  };

  useEffect(() => {
    generateClients(client);
  }, [client]);

  useEffect(() => {
    getClient();
    getExpenseList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Courier
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              InputLabelProps={{ shrink: true }}
              value={date ? new Date(date) : null}
              onChange={(newValue) => {
                // updateQuoObject("date", newValue);
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            value={workOrderNumber}
            InputLabelProps={{ shrink: true }}
            label="Work Order Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setWorkOrderNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clients}
            value={
              clients.find((lab) => lab.label.split(",")[0] == clientId) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Client Name" />
            )}
            onChange={(event, newInputValue) => {
              let value = newInputValue?.label;
              setClientId(parseInt(value?.split(",")[0]));
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            value={place}
            InputLabelProps={{ shrink: true }}
            label="Place"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPlace(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            value={courierPartner}
            InputLabelProps={{ shrink: true }}
            label="Courier Partner"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setCourierPartner(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            value={discription}
            label="discription"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDiscription(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="purpose"
            size="small"
            value={purpose}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPurpose(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="trackingNo"
            value={trackingNo}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setTrackingNo(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={executing}
          onClick={() => {
            onSubmit();
          }}
        >
          update
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
