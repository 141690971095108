import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { generateNumber } from "../../utils/utils";
import ProductDetails, { fetchAddresses } from "../invoice/productDetails";
import { createQuotationGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const gstTypes = [
  { value: 1, label: "State GST" },
  { value: 2, label: "Central Gst" },
  { value: 3, label: "Non Gst" },
];

export default function EditQuotation(props) {
  const createQuotationGuides = createQuotationGuide();
  const [filterProductList, setFilterProductList] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const userType = localStorage.getItem("type");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const [productReadingRows, setproductReadingRows] = React.useState([]);
  const params = useParams();
  const [quotationID, setQuotationID] = React.useState(null);
  const [allAddress, setAllAddress] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [gstPercentageOptions, setGstPercentageOptions] = React.useState([]);
  const [enquiryData, setEnquiryData] = React.useState({});
  const [quotationObject, updateQuotationObject] = React.useState({
    subject: "Quotation for Calibration of Equipment",
    enquiryId: params.enquiryId ? params.enquiryId : null,
    enquiryDate: params.enquiryDate ? params.enquiryDate : null,
    quotationValidity: 0,
    productDetails: "",
    totalAmount: "",
    clientId: "",
    quotationNumber: "",
    referenceNumber: "",
    equipmentCollection: "",
    equipmentDelivery: "",
    date: "",
    gstType: "",
    companyName: "",
    address: "",
    termsConditions: "",
    conveyanceCharges: "",
    courierCharges: "",
    discount: null,
    discountAmount: "",
    GSTPercentage: null,
  });
  const userName = localStorage.getItem("id");
  const [loading, setLoading] = React.useState(false);
  const [isMergedProduct, setIsMergedProduct] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const debouncedFetchAddresses = async (addressIds, shippingAddressIds) => {
    setLoading(true);
    const newAddressArray = await fetchAddresses(
      addressIds,
      shippingAddressIds
    );
    setAllAddress(newAddressArray);
    setLoading(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = async (event) => {
    const AllProducts = productReadingRows.map(({ id, ...product }) => ({
      ...product,
      type: 1,
      referenceId: quotationID || quotationObject.id,
      breakupId: 1,
    }));

    let clientId = 0;

    const client = clientList.find((e) => e.id == params?.clientId);
    if (client && client.id) {
      clientId = parseInt(client.id);
    } else if (quotationObject?.companyName?.id) {
      clientId = parseInt(quotationObject.companyName.id);
    }

    const rows = {
      // productDetails: JSON.stringify(rows1),
      totalAmount: grandTotal,
      clientId,
      quotationNumber: quotationObject.quotationNumber,
      referenceNumber: quotationObject.referenceNumber,
      enquiryId: quotationObject.enquiryId || null,
      enquiryDate: quotationObject.enquiryDate
        ? moment(quotationObject.enquiryDate).format("YYYY-MM-DD")
        : null,
      quotationValidity: quotationObject.quotationValidity || 0,
      quantityMerge: isMergedProduct || 0,
      equipmentCollection: quotationObject.equipmentCollection,
      address:
        quotationObject?.address ||
        `${allAddress[0]?.id},${allAddress[0]?.shipping?.id}`,
      equipmentDelivery: quotationObject.equipmentDelivery,
      subject: quotationObject.subject,
      date: quotationObject.date
        ? moment(quotationObject.date).format("YYYY-MM-DD")
        : null,
      termsConditions: quotationObject.termsConditions || defaultReamrk,
      conveyanceCharges: quotationObject.conveyanceCharges,
      courierCharges: quotationObject.courierCharges,
      discount: quotationObject.discount || null,
      gstType: quotationObject.gstType?.value || "",
      gstpercentages: quotationObject.GSTPercentage || null,
      createdBy: userName,
      // enquiryCreatedBy: enquiryData?.createdby || 0,
    
    };

    const url = BASE_URL;
    try {
      if (params.quotationId) {
        const res = await axiosWithToken.patch(
          url + `quotation/${quotationObject.id}`,
          rows
        );

        const sqlQuery = {
          query: `DELETE FROM productDetails WHERE type = 1 AND referenceId = ${quotationObject.id}`,
        };
        await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
        await axiosWithToken.post(url + "productDetails/bulk", AllProducts);

        setTimeout(refresh, 500);
        toast("Quotation edit successfully !");
      } else {
        const res = await axiosWithToken.post(url + "quotation", rows);
        await axiosWithToken.post(url + "productDetails/bulk", AllProducts);

        setTimeout(refresh, 500);
        toast("Quotation created successfully !");
      }
    } catch (err) {
      let trace = new Error().stack;
      toast.error(
        <h6
          onClick={() => openErrorModal(err.message, trace, "Edit Quotation")}
        >
          <u>Error: {err.message}</u>
        </h6>
      );
    }
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    return axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            contact: client.contact,
            address: client.address,
            shippingAddress: client?.shippingAddress,
          });
        });
        setClientList(pushArray);
        return pushArray;
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getCustomer", "Edit Quotation")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  };

  const fetchaEnquiryData=()=>{
    axiosWithToken.post(BASE_URL + `dynamic`,{"query":`SELECT e.createdby, u.userName FROM enquiry e LEFT JOIN users u ON e.createdby = u.id WHERE e.id =${params.enquiryId}`})
    .then((res)=>{
      setEnquiryData(res?.data[0])

    })
    .catch((err)=>console.log(err))
  }

  // api calls
  async function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let remark = null;
      let qtNumber = null;
      res.data.map((setting) => {
        if (setting.keyName === "quotation terms") {
          remark = setting.value;
        }
        if (setting.keyName === "QuotationNumber") {
          qtNumber = setting.value;
        }
        if (setting.keyName === "gstPercentages") {
          let newKeys = setting.value.split(",");
          const arrayOfObjects = newKeys.map((value) => ({
            value: parseFloat(value),
            label: value,
          }));
          setGstPercentageOptions(arrayOfObjects);
        }
      });
      // setQuotation(setting.value)
      !params.quotationId &&
        axiosWithToken
          .get(BASE_URL + "quotation?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let qt = generateNumber(
              qtNumber,
              `${res.data.length > 0 ? res.data[0].id + 1 : ""}`
            );
            let idIs = res?.data[0]?.id + 1 || 0;
            setQuotationID(idIs);
            updateQuoObject("quotationNumber", qt);
          })
          .catch((err) => {
            toast.error(
              <h6
                onClick={() =>
                  openErrorModal(err.message, "fetchSettings", "Edit Quotation")
                }
              >
                <u>Error: {err.message}</u>
              </h6>
            );
          });
      setDefaultRemark(remark);
    });
  }

  const fetchDataAndProcess = async (Id, isMergedProduct) => {
    try {
      let allProducts = [];
      let sqlQuery = {
        query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${Id}`,
      };

      const response = await axiosWithToken.post(
        BASE_URL + `dynamic`,
        sqlQuery
      );
      allProducts = response.data;

      const processedProducts = isMergedProduct
        ? Object.values(
            allProducts.reduce((acc, obj) => {
              const key = obj.rate + "_" + obj.equipmentId;
              if (acc[key]) {
                acc[key].quantity += obj.quantity;
              } else {
                acc[key] = { ...obj };
              }
              return acc;
            }, {})
          )
        : allProducts;

      setproductReadingRows(processedProducts);
    } catch (err) {
      console.error("Error fetching and processing data:", err);
      let trace = new Error().stack;
      toast.error(
        <h6
          onClick={() => openErrorModal(err.message, trace, "Edit Quotation")}
        >
          <u>Error: {err.message}</u>
        </h6>
      );
    }
  };

  const fetchQutationDetails = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.productDetails,qt.id,qt.gstpercentages,qt.gstType,qt.quotationNumber,qt.referenceNumber,qt.date,qt.quantityMerge,qt.enquiryId,qt.enquiryDate,qt.totalAmount,client.companyName,client.contact,qt.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.conveyanceCharges,qt.courierCharges,qt.discount,qt.quotationValidity,qt.equipmentCollection,qt.equipmentDelivery,qt.clientId&_where=(qt.quotationNumber,like,${params.quotationId})`
      )
      .then(async (res) => {
        let quotationData = res.data[0];
        let newData = {
          ...quotationObject,
          id: quotationData?.qt_id,
          productDetails: quotationData?.qt_productDetails || "",
          totalAmount: quotationData?.qt_totalAmount || "",
          clientId: quotationData?.qt_clientId || "",
          enquiryId:
            quotationData?.qt_enquiryId && quotationData?.qt_enquiryId != ""
              ? quotationData?.qt_enquiryId
              : "",
          enquiryDate: quotationData?.qt_enquiryDate || "",
          quotationNumber: quotationData?.qt_quotationNumber || "",
          referenceNumber: quotationData?.qt_referenceNumber || "",
          quotationValidity: quotationData?.qt_quotationValidity || "",
          equipmentCollection: quotationData?.qt_equipmentCollection || "",
          equipmentDelivery: quotationData?.qt_equipmentDelivery || "",
          subject: quotationData?.qt_subject || "",
          date: quotationData?.qt_date || "",
          GSTPercentage: quotationData?.qt_gstpercentages || null,
          gstType:
            quotationData?.qt_gstType &&
            gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              ? gstTypes.filter((e) => e.value == quotationData?.qt_gstType)[0]
              : "",
          companyName:
            inpt[0]?.filter((e) => e.id == quotationData?.client_id)[0] || "",
          address: quotationData?.qt_address,
          termsConditions: quotationData?.qt_termsConditions || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
          courierCharges: quotationData?.qt_courierCharges || "",
          discount: quotationData?.qt_discount || "",
        };
        quotationData && setIsMergedProduct(quotationData?.qt_quantityMerge);
        quotationData && updateQuotationObject(newData);
        quotationData && setGrandTotal(quotationData?.qt_totalAmount);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchQutationDetails" ,"Edit Quotation")}><u>Error: {err.message}</u></h6>);
      });
  };

  const updateQuoObject = (key, value, q) => {
    let newQuoObject = {
      ...quotationObject,
    };

    newQuoObject[key] = value;

    updateQuotationObject({ ...newQuoObject });
  };

  const filterProductListByCompany = () => {
    let clientID = quotationObject?.companyName?.id || params.clientId;

    const filteredList = productList.filter(
      (item) => item.clientId === clientID || item.clientId === 0
    );

    const uniqueList = Object.values(
      filteredList.reduce((map, item) => {
        if (!map[item.instrumentId] || item.clientId === clientID) {
          map[item.instrumentId] = item;
        }
        return map;
      }, {})
    );

    let productsArray = [];
    uniqueList.map((customerProduct) => {
      productsArray.push({
        id: customerProduct.id,
        label: `${customerProduct.id}, ${customerProduct.instrumentName}`,
        rate: customerProduct.rate,
        key: customerProduct.id,
        name: customerProduct.instrumentName,
      });
    });

    setFilterProductList(productsArray);
  };

  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    return axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);

        return res.data;
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"Edit Quotation")}><u>Error: {err.message}</u></h6>);
      });
  };

  useEffect(() => {
    Promise.all([getCustomer(), fetchSettings()]).then((res) => {
      if (params.quotationId) fetchQutationDetails(res);
    });
  }, []);

  useEffect(()=>{
   if(quotationObject?.id) fetchDataAndProcess(quotationObject?.id, isMergedProduct);
  },[quotationObject?.id,isMergedProduct])

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    filterProductListByCompany();
  }, [quotationObject?.companyName?.id, params.clientId, productList]);

  
  useEffect(() => {
    if(params.enquiryId)fetchaEnquiryData()
  }, [params.enquiryId]);

  useEffect(async () => {
    if (params.enquiryId || params.clientId) {
      let Client = clientList?.filter((e) => e.id == params.clientId)[0];

      const addressIds = Client?.address;
      const shippingAddressIds = Client?.shippingAddress;

      let newAddressArray = await fetchAddresses(
        addressIds,
        shippingAddressIds
      );
      setAllAddress(newAddressArray);
      if (params.enquiryId) {
        updateQuotationObject((prevState) => ({
          ...prevState,
          clientId: Client?.id,
          companyName: Client?.label,
          address: Client?.address,
          shippingAddress: Client?.shippingAddress,
        }));
      }
    }
  }, [quotationObject?.companyName]);


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create/Update Quotation
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          createQuotationGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        {/* <Grid item xs={3}>
          <Autocomplete
            disabled={!!params.quotationId || !!params.enquiryId}
            size="small"
            id="quotation_create_company"
            options={clientList}
            value={
              clientList?.filter((e) => e.id == params.clientId)[0] ||
              quotationObject.companyName
            }
            renderInput={(params) => (
              <TextField {...params} label="Client *" />
            )}
            onChange={async (event, value) => {
              const addressIds = value?.address
              const shippingAddressIds = value?.shippingAddress
    
             let newAddressArray = await fetchAddresses(addressIds,shippingAddressIds)
              setAllAddress(newAddressArray);
              clientList.map((client) => {
                updateQuoObject("companyName", value);
              });
            }}
          />
        </Grid> */}
 <Grid item xs={3}>
      <Autocomplete
        disabled={!!params.quotationId || !!params.enquiryId}
        size="small"
        id="quotation_create_company"
        options={clientList}
        value={
          clientList?.filter((e) => e.id == params.clientId)[0] ||
          quotationObject.companyName
        }
        renderInput={(params) => (
          <TextField {...params} label="Company Name *" />
        )}
        onChange={async (event, value) => {
          const addressIds = value?.address;
          const shippingAddressIds = value?.shippingAddress;

          // Debounce the fetchAddresses function here
          debouncedFetchAddresses(addressIds, shippingAddressIds);

          clientList.map((client) => {
            updateQuoObject('companyName', value);
          });
        }}
      />
      {loading && <p>Loading...</p>} {/* Display a loading indicator */}
    </Grid>

        <Grid item xs={3} id="quotation_create_address">
          {allAddress?.length === 1 || params?.quotationId ? (
            <TextField
            // value={(allAddress?.[0]?.address) || (quotationObject?.address) || ""}
            value={
              allAddress?.length === 1
                ? allAddress[0]?.address // If allAddress has only one element, use its address
                : allAddress.find((e) => e.id == (quotationObject?.address).split(",")?.[0])?.address || ""
            }
              id="outlined-basic"
              label="Billing Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              size="small"
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                updateQuoObject("address", `${value?.id},${value?.shipping?.id}`);
                setSelectedAddress(value)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Billing Address *" variant="outlined" />
              )}
            />
          )}
        </Grid>

        <Grid item xs={3}>
        <TextField
        value={
          allAddress?.length === 1
            ? allAddress[0]?.shipping?.address // If allAddress has only one element, use its shipping address
            : selectedAddress?.shipping?.address || 
            allAddress.find((e) => e.id == (quotationObject?.address).split(",")?.[0])?.shipping?.address  || ""
              
        }
        
              id="outlined-basic"
              label="Shipping Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_quotationNumber"
            label="Quotation Number *"
            size="small"
            value={quotationObject.quotationNumber || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={3} id="quotation_create_qtDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              InputLabelProps={{ shrink: true }}
              value={quotationObject.date ? new Date(quotationObject.date) : ""}
              onChange={(newValue) => {
                updateQuoObject("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_enquiryId"
            label="Enquiry Id "
            size="small"
            fullWidth
            variant="outlined"
            value={quotationObject.enquiryId || ""}
            onChange={(e) => {
              updateQuoObject("enquiryId", e.target.value);
            }}
          />
        </Grid>
        {params.enquiryId && (
  <Grid item xs={3}>
  <TextField
  disabled
    label="Enquiry CreateBy "
    size="small"
    fullWidth
    variant="outlined"
    value={enquiryData?.userName || ""}
    // onChange={(e) => {
    //   updateQuoObject("enquiryId", e.target.value);
    // }}
  />
</Grid>
        )}
      

        <Grid item xs={3} id="quotation_create_enquiryDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Enquiry Date "
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                quotationObject.enquiryDate
                  ? new Date(quotationObject.enquiryDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("enquiryDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_referenceNumber"
            label="Reference Number"
            size="small"
            value={quotationObject.referenceNumber || ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("referenceNumber", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_subject"
            label="Subject *"
            size="small"
            value={quotationObject.subject || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("subject", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="quotation_create_termsConditions"
            label="Terms Conditions *"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={2}
            multiline
            value={
              quotationObject.termsConditions
                ? quotationObject.termsConditions
                : defaultReamrk
            }
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              updateQuoObject("termsConditions", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="quotation_create_gstType"
            value={quotationObject?.gstType}
            options={gstTypes}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onChange={(event, val) => {
              updateQuoObject("gstType", val);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="quotation_create_gstPercentage"
            value={
              gstPercentageOptions.find(
                (val) => val.value === quotationObject?.GSTPercentage
              ) || null
            }
            options={gstPercentageOptions}
            getOptionLabel={(option) => `${option.label} %`}
            renderInput={(params) => (
              <TextField {...params} label="GST Percentage" />
            )}
            onChange={(event, val) => {
              updateQuoObject("GSTPercentage", val?.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="quotation_create_quotationValidity"
            label="Quotation Validity "
            size="small"
            value={quotationObject.quotationValidity || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("quotationValidity", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_equipmentCollection"
            label="Equipment Collection "
            size="small"
            value={quotationObject.equipmentCollection || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentCollection", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quotation_create_equipmentDelivery"
            label="Equipment Delivery"
            size="small"
            value={quotationObject.equipmentDelivery || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentDelivery", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3} >
          <FormControlLabel
            control={
              <Checkbox
                checked={isMergedProduct  == 1}
                onChange={(e) => {
                  setIsMergedProduct(e.target.checked ? 1 : 0);
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1" >With Merged Product Quantity  </Typography>
            }
          />
        </Grid>
      </Grid>

      <div>
        <ProductDetails
          productArray={filterProductList}
          setproductReadingRows={setproductReadingRows}
          productReadingRows={productReadingRows}
          // state={state}
          // setState={setState}
          updateQuoObject={updateQuoObject}
          grandTotal={grandTotal}
          setGrandTotal={setGrandTotal}
          invoiceObject={quotationObject}
          isMergedProduct={isMergedProduct}
          isInvoice={false}
        
        />
      </div>

      <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem", }}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
           onClick={() => {
    if (window.confirm("Are you sure you want to cancel?")) {
      window.history.back();
    }
  }}
        >
          Cancel
        </Button>
        {userType !== "3" ? (
          <Button
            id="quotation_create_save"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              if (
                quotationObject.gstType?.value !== null &&
                quotationObject.companyName &&
                quotationObject.date &&
                productReadingRows.length > 0
              ) {
                handleSubmit();
              } else {
                if (!quotationObject.gstType?.value) {
                  toast.error("Please select GST Type!");
                }
                if (!quotationObject.companyName) {
                  toast.error("Please select Client!");
                }
                if (!quotationObject.date) {
                  toast.error("Please Select Date!");
                }
                if (!productReadingRows.length) {
                  toast.error("Please Add Product and Quantity!");
                }
              }
            }}
          >
            {params?.clientId ? "Update" : "Save"}
          </Button>
        ) : (
          console.log("")
        )}
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Paper>
  );
}
