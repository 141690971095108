import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Toolbar,
} from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";


export default function EditRange(props) {
  const [instrumentNames, setInstrumentName] = React.useState(null);
  const [ranges, setRanges] = React.useState("");
  const [calibrationPoints, setCalibrationPoints] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);

  const params = useParams();
  const getRanges = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=range.ranges,_j,ins.instruments&_on1=(range.instrumentId,eq,ins.id)&_fields=ins.instrumentName,ins.id,range.ranges,range.calibrationPoints,range.id&_where=(range.id,eq,${params.id})~and(range.status,eq,1)`
      )
      .then((res) => {
        console.log("innder", res.data[0].ins_instrumentName);
        setRanges(res.data[0].range_ranges);
        setCalibrationPoints(res.data[0].range_calibrationPoints);
        setInstrumentName(res.data[0].ins_id);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getInstrumentsList();
    getRanges();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      instrumentId: instrumentNames,
      ranges: ranges,
      calibrationPoints: calibrationPoints,
    };

    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };

    axiosWithToken
      .patch(url + `ranges/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("updated range!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Ranges
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={instrumentArray.map(({ id, instrumentName }) => ({
              label: id + ", " + instrumentName,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Instruments" />
            )}
            onInputChange={(event, newInputValue) => {
              var splArr = newInputValue.split(",");
              setInstrumentName(splArr.length ? parseInt(splArr[0]) : "");
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Range"
            value={ranges}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setRanges(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Calibration Points "
            value={calibrationPoints}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setCalibrationPoints(e.target.value)}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Edit Discipline
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
