//po

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import ProductDetails from "../invoice/productDetails";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const CreatePO_Acceptance = () => {
  const [quotationData, setquotationData] = useState([]);
  const [clientId, setSelectedClientId] = useState(null);
  const [quotationNumberByID, setQuotationNumberByID] = useState(null);
  const [quotationNumber, setQuotationNumber] = useState("");
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [productArray, setProductArray] = React.useState([]);
  const [quotationDate, setQuotationDate] = useState("");
  const [poFileUrl, setpoFileUrl] = useState("");
  const [poAacceptanceData, createPoAacceptance] = useState({});
  const [productReadingRows, setproductReadingRows] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [referenceID, setReferenceID] = React.useState(null);
  const params = useParams();
  const [invoiceObject, updateInvoiceObject] = React.useState({
    subject: "Invoice for Calibration of Equipment",
    challanNo: "",
    challanDate: "",
    productDetails: "",
    totalAmount: "",
    clientId: "",
    quotationNumber: "",
    referenceNumber: "",
    invoiceDate: "",
    // invoiceNumber: "",
    gstType: "",
    companyName: "",
    address: "",
    termsConditions: "",
    conveyanceCharges: "",
    courierCharges: "",
    selectedSRF: [],
    equipmentDelivery: "",
    srfInstrumentIdsArray: [],
    discount: null,
    discountAmount: "",
  });
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const updatePOObject = (key, value) => {
    let newPoAacceptance = {
      ...poAacceptanceData,
    };
    newPoAacceptance[key] = value;
    createPoAacceptance({ ...newPoAacceptance });
  };

  const getquotationData = () => {
    let url = BASE_URL;
    let data = {
      query:
        "SELECT * FROM quotation WHERE status = 1 AND id NOT IN (SELECT quotationId FROM poAcceptance)",
    };
    axiosWithToken
      .post(url + `dynamic`, data)
      .then((res) => {
        setquotationData(res.data);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getquotationData" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });

    let data1 = {
      query: "SELECT id, quotationNumber FROM quotation WHERE status = 1",
    };
    axiosWithToken
      .post(url + `dynamic`, data1)
      .then((res) => {
        setQuotationNumberByID(res.data);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getquotationData" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchPoData = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `poAcceptance/${params.id}`)
      .then(async (res) => {
        let dataObject = res.data[0];

        // Update only the required fields in the state
        updateInvoiceObject((prevState) => ({
          ...prevState,
          conveyanceCharges: dataObject?.conveyanceCharges,
          courierCharges: dataObject?.courierCharges,
          discount: dataObject?.discount,
        }));

        let newData = {
          ...poAacceptanceData,
          ...dataObject,
        };
        dataObject && createPoAacceptance(newData);

        let allProducts = [];
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 3 AND referenceId = ${params?.id}`,
        };

        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        allProducts = response.data;

        const mergedProducts = Object.values(
          allProducts.reduce((acc, obj) => {
            const key = obj.rate + "_" + obj.equipmentId;
            if (acc[key]) {
              acc[key].quantity += obj.quantity;
            } else {
              acc[key] = { ...obj };
            }
            return acc;
          }, {})
        );

        setproductReadingRows(mergedProducts);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchPoData" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleFileUpload12 = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setpoFileUrl(res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "handleFileUpload12" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });
  };

  const handleSubmit = () => {
    const payload = {
      quotationDate: params.id
        ? moment(poAacceptanceData?.quotationDate).format("YYYY-MM-DD")
        : moment(quotationDate).format("YYYY-MM-DD"),
      quotationId: params.id
        ? poAacceptanceData?.quotationId
        : selectedQuotationId,
      poFileUrl: poFileUrl,
      poNumber: poAacceptanceData.poNumber,
      conveyanceCharges: invoiceObject.conveyanceCharges,
      courierCharges: invoiceObject.courierCharges,
      discount: invoiceObject.discount || null,
      poDate: moment(poAacceptanceData.poDate).format("YYYY-MM-DD"),
      amount: poAacceptanceData.amount || grandTotal,
    };

    // Create a new array by mapping the existing array and adding the specified properties to each object
    const AllProducts = productReadingRows.map(({ id, ...product }) => ({
      ...product,
      type: 3,
      referenceId: params.id || referenceID,
      breakupId: 1,
    }));

    let url = BASE_URL;

    if (params.id) {
      axiosWithToken
        .patch(url + `poAcceptance/${params.id}`, payload)
        .then((res) => {
          let sqlQuery = {
            query: `DELETE FROM productDetails WHERE type = 3 AND referenceId = ${params.id}`,
          };

          axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);

          axiosWithToken
            .post(url + "productDetails/bulk", AllProducts)
            .then((res) => {
              // setTimeout(refresh, 500);
            })
            .catch((err) => {
              toast.error(<h6 onClick={() => openErrorModal(err.message, "productDetailsbulk" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
            });

          setTimeout(refresh, 500);

          toast("poAcceptance edit successfully !");
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "productDetailsbulk" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
        });
    } else {
      axiosWithToken
        .post(url + "poAcceptance", payload)
        .then((res) => {
          axiosWithToken
            .post(url + "productDetails/bulk", AllProducts)
            .then((res) => {
              // setTimeout(refresh, 500);
            })
            .catch((err) => {
              toast.error(<h6 onClick={() => openErrorModal(err.message, "productDetailsbulk" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
            });
          setTimeout(refresh, 500);
          toast("poAcceptance created successfully !");
        })
        .catch((err) => {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "handleSubmit" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
        });
    }
  };

  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        let productsArray = [];
        res.data.map((customerProduct) => {
          productsArray.push({
            id: customerProduct.id,
            label: `${customerProduct.id}, ${customerProduct.instrumentName}`,
            rate: customerProduct.rate,
            key: customerProduct.id,
            name: customerProduct.instrumentName,
            instrumentId: customerProduct.instrumentId,
          });
        });
        setProductArray(productsArray);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });
  };

  const updateQuoObject = (key, value, q) => {
    let newQuoObject = {
      ...invoiceObject,
    };

    newQuoObject[key] = value;

    updateInvoiceObject({ ...newQuoObject });
  };

  const fetchQutationDetails = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.productDetails,qt.id,qt.gstType,qt.quotationNumber,qt.referenceNumber,qt.date,qt.enquiryId,qt.enquiryDate,qt.totalAmount,client.companyName,client.contact,qt.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.conveyanceCharges,qt.courierCharges,qt.discount,qt.quotationValidity,qt.equipmentCollection,qt.equipmentDelivery,qt.clientId&_where=(qt.quotationNumber,like,${quotationNumber})`
      )
      .then(async (res) => {
        let quotationData = res.data[0];
        let newData = {
          ...invoiceObject,
          id: quotationData?.qt_id,
          productDetails: quotationData?.qt_productDetails || "",
          totalAmount: quotationData?.qt_totalAmount || "",
          clientId: quotationData?.qt_clientId || "",
          enquiryId:
            quotationData?.qt_enquiryId && quotationData?.qt_enquiryId != ""
              ? quotationData?.qt_enquiryId
              : "",
          enquiryDate: quotationData?.qt_enquiryDate || "",
          quotationNumber: quotationData?.qt_quotationNumber || "",
          referenceNumber: quotationData?.qt_referenceNumber || "",
          address: quotationData?.qt_address,
          termsConditions: quotationData?.qt_termsConditions || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
          equipmentDelivery: quotationData?.qt_equipmentDelivery || "",
          courierCharges: quotationData?.qt_courierCharges || "",
          discount: quotationData?.qt_discount || "",
        };

        createPoAacceptance((prevState) => ({
          ...prevState,
          discount: quotationData?.qt_discount || "",
          courierCharges: quotationData?.qt_courierCharges || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
        }));
        quotationData && updateInvoiceObject(newData);
        quotationData && setGrandTotal(quotationData?.qt_totalAmount);

        let allProducts = [];
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${newData?.id}`,
        };

        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        allProducts = response.data;

        const mergedProducts = Object.values(
          allProducts.reduce((acc, obj) => {
            const key = obj.rate + "_" + obj.equipmentId;
            if (acc[key]) {
              acc[key].quantity += obj.quantity;
            } else {
              acc[key] = { ...obj };
            }
            return acc;
          }, {})
        );

        setproductReadingRows(mergedProducts);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchQutationDetails" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
      });
  };

  const fetchSettings = () => {
    !params.id &&
      axiosWithToken
        .get(BASE_URL + "poAcceptance?_fields=id&_sort=-id&_size=1")
        .then((res) => {
          let idIs = res?.data[0]?.id + 1 || 1;
          setReferenceID(idIs);
        }).catch((err)=>{
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"CreatePoAacceptance")}><u>Error: {err.message}</u></h6>);
        });
  };

  useEffect(() => {
    getProductList();
    getquotationData();
    fetchSettings();
    if (params.id) fetchPoData();
  }, []);

  useEffect(() => {
    if (quotationNumber) fetchQutationDetails();
  }, [quotationNumber]);

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          {params.id ? (
            <TextField
              {...params}
              label="Quotation ID *"
              disabled
              value={
                quotationNumberByID?.filter(
                  (ele) => ele.id == poAacceptanceData?.quotationId
                )?.[0]?.quotationNumber || ""
              }
              size="small"
            />
          ) : (
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={quotationData}
              getOptionLabel={(option) => option.quotationNumber}
              renderInput={(params) => (
                <TextField {...params} label="Quotation Number *" />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  const selectedQuotation = quotationData.find(
                    (quotation) =>
                      quotation.quotationNumber === newValue.quotationNumber
                  );
                  if (selectedQuotation) {
                    setQuotationNumber(selectedQuotation.quotationNumber);
                    setQuotationDate(selectedQuotation.date);
                    setSelectedQuotationId(selectedQuotation.id);
                    setSelectedClientId(selectedQuotation.clientId);
                  } else {
                    setQuotationNumber("");
                    setQuotationDate("");
                    setSelectedQuotationId("");
                    setSelectedClientId("");
                  }
                } else {
                  setQuotationNumber("");
                  setQuotationDate("");
                  setSelectedQuotationId("");
                  setSelectedClientId("");
                }
              }}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Date"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            value={
              params.id
                ? moment(poAacceptanceData?.quotationDate).format("YYYY-MM-DD")
                : moment(quotationDate).format("YYYY-MM-DD") || null
            }
            onChange={(e) =>
              setQuotationDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Po Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={poAacceptanceData?.poNumber || ""}
            onChange={(e) => {
              updatePOObject("poNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Po Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                poAacceptanceData.poDate
                  ? new Date(poAacceptanceData.poDate)
                  : null
              }
              onChange={(newValue) => {
                updatePOObject("poDate", moment(newValue).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Amount *"
            size="small"
            fullWidth
            variant="outlined"
            value={grandTotal || poAacceptanceData?.amount || ""}
            onChange={(e) => {
              updatePOObject("amount", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Input
            id="file-upload-input1"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload12(e)}
          />
          <label htmlFor="file-upload-input1">
            <Button variant="contained" component="span">
              Upload File
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary">
            Select a file to upload
          </Typography>
        </Grid>
      </Grid>

      <div>
        <ProductDetails
          productArray={productArray}
          setproductReadingRows={setproductReadingRows}
          productReadingRows={productReadingRows}
          updateQuoObject={updateQuoObject}
          grandTotal={grandTotal}
          setGrandTotal={setGrandTotal}
          invoiceObject={invoiceObject}
          isInvoice={false}
        />
      </div>

      <br />

      <Box>
        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
            marginTop: "3rem",
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: "grey" }}
            size="small"
            onClick={() => {
              if (window.confirm("Are you sure you want to cancel?")) {
                window.history.back();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleSubmit()}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Box>
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </div>
  );
};

export default CreatePO_Acceptance;
