import React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export default function CreatePickup(props) {
  const [srfObject, createSrfObject] = React.useState({});

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    let row = {
      nameOfTheCompany: srfObject.nameOfTheCompany,
      contactPersonName: srfObject.contactPersonName,
      date: srfObject.date
        ? moment(srfObject.date).format("YYYY-MM-DD")
        : moment(new Date()),
      phone: srfObject.phone,
      location: srfObject.location,
      email: srfObject.email,
      quantityOfInstruments: srfObject.quantityOfInstruments,
      purposeOfPickUp: srfObject.purposeOfPickUp,
      distance: srfObject.distance,
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + "pickup", row)
      .then((res) => {
        props.setLoader(false);
        toast("Pickup created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Pickup
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Name Of The Company"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("nameOfTheCompany", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Person Name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("contactPersonName", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.date ? srfObject.date : new Date()}
              onChange={(newValue) => {
                updateSrfObject("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Phone"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("phone", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Location"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("location", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("email", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quantity Of Instruments"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("quantityOfInstruments", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Purpose Of Pickup"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("purposeOfPickUp", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Distance"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("distance", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
