import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { BASE_URL } from "../../global";
import Tooltip from "@mui/material/Tooltip";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { getUsers } from "../../utils/databaseHelper";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No." },
  { id: "companyName", label: "Client ", minWidth: 40 },
  { id: "address", label: "Address ", minWidth: 40 },
  // {
  //   id: "outwardDate",
  //   label: "outward date ",
  //   align: "left",
  //   minWidth: 40,
  // },
  // {
  //   id: "siteInwardDate",
  //   label: "Site Inward Date",
  //   minWidth: 40,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
  // {
  //   id: "expectedReturnDate",
  //   label: "expected return date",
  //   minWidth: 40,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
  // {
  //   id: "actualReturnDate",
  //   label: "Actual Return date ",
  //   minWidth: 40,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
];

function createData(
  id,
  companyName,
  address,
  outwardDate,
  userIds,
  expectedReturnDate,
  siteInwardDate,
  actualReturnDate,
  validUpto
) {
  return {
    id,
    companyName,
    address,
    outwardDate,
    userIds,
    expectedReturnDate,
    siteInwardDate,
    actualReturnDate,
    validUpto,
  };
}

export default function StandardsInOutList() {
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standardList, setStandardList] = React.useState("");
  const [stInOutData, setStInOutData] = React.useState([]);
  const [engineers, setEngineers] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    let query = {
      query: `DELETE FROM standardInOut WHERE clientId = ${id}`,
    };
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        toast("Deleted standard In/Out!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const getEngineers = () => {
    getUsers(2, (res) => {
      return res.data.map((e) => {
        return { ...e, label: e.userName };
      });
    })
      .then((engineers) => {
        setEngineers(engineers);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "cl.companyName",
        "Expected Return Date": "si.expectedReturnDate",
        "Site Inward Date": "si.siteInwardDate",
        "Actual Return Date": "si.actualReturnDate",
        "Outward Date": "si.outwardDate",
      }[searchBy];
      if (colName.includes("Date")) {
        whereConditions = `where ${colName} between '${moment(from).format(
          "YYYY-MM-DD"
        )}' and '${moment(to).format("YYYY-MM-DD")}'`;
      } else {
        whereConditions = `where ${colName} like '%${searchKey}%'`;
      }
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " si.status = 1 ";

    let data = {
      query: `SELECT si.clientId, cl.companyName, si.standardId, si.purpose, si.outwardDate, si.expectedReturnDate, si.siteInwardDate, si.userId, si.remark, si.id AS standardInOutId, a.address AS address FROM (SELECT clientId, MIN(id) AS min_id FROM standardInOut WHERE status = 1 GROUP BY clientId) AS unique_clients JOIN standardInOut AS si ON unique_clients.min_id = si.id LEFT JOIN clients AS cl ON si.clientId = cl.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(si.address, ',', 1), ',', -1) ${whereConditions} order by si.id DESC ${pagination_settings}`,
    };
    return data;
  };

  const getStandardInOutList = (event) => {
    const data = getData();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setStandardList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const initializeStInOutData = () => {
    var rowDaata = [];
    for (let i = 0; i < standardList.length; i++) {
      rowDaata.push(
        createData(
          standardList[i].clientId,
          standardList[i].companyName,
          standardList[i].address,
          standardList[i].outwardDate
            ? moment(standardList[i].outwardDate).format("DD-MM-YYYY")
            : "",
          standardList[i].expectedReturnDate
            ? moment(standardList[i].expectedReturnDate).format("DD-MM-YYYY")
            : "",
          standardList[i].siteInwardDate
            ? moment(standardList[i].siteInwardDate).format("DD-MM-YYYY")
            : "",
          standardList[i].actualReturnDate
            ? moment(standardList[i].actualReturnDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setStInOutData(rowDaata);
  };

  const search = () => {
    getStandardInOutList();
  };

  useEffect(() => {
    getStandardInOutList();
    getEngineers();
  }, []);

  useEffect(() => {
    initializeStInOutData();
  }, [standardList]);

  return (
    <Paper sx={{ p: 1 }}>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/standardInOutList/editStandardInOut/0"
          >
            <b>ADD NEW STANDARD In/Out</b>
          </Button>
        </Toolbar>
        <hr />

        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "right",
            justifyContent: "flex-end",
          }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={[
              { key: "cl.companyName", label: "Client" },
              // { key: "si.expectedReturnDate", label: "Expected Return Date" },
              // { key: "si.siteInwardDate", label: "Site Inward Date" },
              // { key: "si.actualReturnDate", label: "Actual Return Date" },
              // { key: "si.outwardDate", label: "Outward Date" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />

          {/* <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginRight: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </div> */}

          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            sx={{ marginLeft: "10px" }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
        <br />

        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stInOutData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip
                          title="Edit standard In/Out"
                          placement="top-start"
                        >
                          <Button
                            style={{
                              borderColor: "#dc3545",
                            }}
                            component={Link}
                            to={`/standardInOutList/editStandardInOut/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Edit standard In/Out"
                          placement="top-start"
                        >
                          <Button
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete standard?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                            style={{
                              borderColor: "#dc3545",
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={stInOutData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </Paper>
  );
}
